@use "../../1-core/colours" as *;
@use "../../1-core/grids-breakpoints" as *;
@use "../../1-core/spacing-scale" as *;
@use "../../1-core/typography/font-weights" as *;

.mobile-nav-control {
    background-color: $color-1-500;
    display: flex;
    align-items: center;
    justify-content: center;
    @include full-bleed;
    padding: $spacing-4 $spacing-5;

    ul {
        display: flex;
    }

    ul li {
        list-style: none;
        margin: 0;
        margin-left: $spacing-4;
        position: relative;
        padding-left: 0;

        a {
            text-decoration: none;
            color: $white;
        }

        &::before {
            content: none;
        }

        &.active {
            a {
                color: $color-2-500;
                @include text-bold;
            }
        }

        &.active::after {
            content: '';
            position: absolute;
            height: $spacing-1;
            width: 100%;
            bottom: 0;
            left: 0;
            background-color: $color-2-500;
            transform: translateY($spacing-1);
            border-radius: 2px;
        }
    }

    p {
        @include text-bold;
    }
}

@media (min-width: $screen-lg) {
    .mobile-nav-control {
        display: none;
    }
}
