@use "../../1-core/" as *;

$link-skin-1-color:                     $color-2-500;
$link-skin-1-hover-color:             red;
$link-skin-1-hover-underline-height:    2px;
$link-skin-1-focus-bg:                  $color-1-500;
$link-skin-1-outline-color:             $link-skin-1-color;
$link-skin-1-outline-width:             2px;
$link-skin-1-outline-offset:            4px;
$link-skin-1-active-color:              $color-1-900;
$link-skin-1-disabled-color:            $grey-1-100;

$link-skin-2-color:                     $grey-1-500;
$link-skin-2-hover-color:              red;
$link-skin-2-hover-underline-height:    2px;
$link-skin-2-focus-bg:                  $grey-1-50;
$link-skin-2-outline-color:             $link-skin-2-color;
$link-skin-2-outline-width:             2px;
$link-skin-2-outline-offset:            4px;
$link-skin-2-active-color:              $color-1-900;
$link-skin-2-disabled-color:            $grey-1-100;

@mixin link-skin($skin: 'primary') {
    text-decoration: none;
    cursor: pointer;
    @include transition(color);
    color: var(--link-color);

    &:not(.link-has-icons) {
        @include transition(box-shadow);
        box-shadow: 0 var(--link-underline-height) 0 var(--link-underline-color);

        &:hover {
            @include transition(box-shadow, 200ms);
        }
    }

    &.link-has-icons {
        &:hover {
            @include transition(color, 200ms);
        }
    }

    &.link-disabled {
        cursor: default;
        transition: box-shadow none;

        &:hover {
            cursor: default;
        }
    }

    @if $skin == 'primary' {
        --link-color: #{$link-skin-1-color};

        &:not(.link-has-icons) {
            --link-underline-height: #{$link-skin-1-hover-underline-height};
            --link-underline-color: #{$link-skin-1-color};

            &:hover {
                box-shadow: 0 0 0;
            }

            &:active {
                --link-color: #{$link-skin-1-active-color};
            }
        }

        &.link-has-icons {
            &:hover {
                --link-color: #{$link-skin-1-hover-color};
            }

            &:active {
                --link-color: #{$link-skin-1-active-color};
            }
        }

        &:focus-visible {
            background-color: $link-skin-1-focus-bg;
            outline: $link-skin-1-outline-width solid $link-skin-1-outline-color;
            outline-offset: $link-skin-1-outline-offset;
        }

        &.link-disabled {
            --link-color: #{$link-skin-1-disabled-color};

            &:active {
                --link-color: #{$link-skin-1-disabled-color};
            }

            &:not(.link-has-icons) {
                --link-underline-height: #{$link-skin-1-hover-underline-height};
                --link-underline-color: #{$link-skin-1-disabled-color};
            }

            &.link-has-icons:hover {
                --link-color: #{$link-skin-1-disabled-color};
            }
        }
    }


    @if $skin == 'secondary' {
        color: $link-skin-2-color;

        &:not(.link-has-icons) {
            --link-underline-height: #{$link-skin-2-hover-underline-height};
            --link-underline-color: #{$link-skin-2-color};

            &:hover {
                box-shadow: 0 0 0;
            }

            &:active {
                --link-color: #{$link-skin-2-active-color};
            }
        }

        &.link-has-icons {
            &:hover {
                --link-color: #{$link-skin-2-hover-color};
            }

            &:active {
                --link-color: #{$link-skin-2-active-color};
            }
        }

        &:focus-visible {
            background-color: $link-skin-2-focus-bg;
            outline: $link-skin-2-outline-width solid $link-skin-2-outline-color;
            outline-offset: $link-skin-2-outline-offset;
        }

        &.link-disabled {
            --link-color: #{$link-skin-2-disabled-color};

            &:active {
                --link-color: #{$link-skin-2-disabled-color};
            }

            &:not(.link-has-icons) {
                --link-underline-height: #{$link-skin-2-hover-underline-height};
                --link-underline-color: #{$link-skin-2-disabled-color};
            }

            &.link-has-icons:hover {
                --link-color: #{$link-skin-2-disabled-color};
            }
        }
    }
}

.link-skin-1 {
    @include link-skin('primary');
}

.link-skin-2 {
    @include link-skin('secondary');
}

@mixin link-size($size: 'md') {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    gap: var(--link-size-gap);

    .svg-icon {
        width: var(--link-icon-width);
        height: var(--link-icon-width);
    }

    @if $size == 'lg' {
        @include text-md;

        --link-size-gap: #{$spacing-2};
        --link-icon-width: #{$icon-lg};
    }

    @if $size == 'md' {
        @include text-base;

        --link-size-gap: #{$spacing-2};
        --link-icon-width: #{$icon-md};
    }

    @if $size == 'sm' {
        @include text-sm;

        --link-size-gap: #{$spacing-1};
        --link-icon-width: #{$icon-sm};
    }
}

.link-size-lg {
    @include link-size('lg');
}

.link-size-md {
    @include link-size('md');
}

.link-size-sm {
    @include link-size('sm');
}
