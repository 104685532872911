@use "../../1-core" as *;

$element-icon-color:                  $grey-1-300;
$element-icon-size:                   $spacing-5;
$element-side-padding:                $spacing-3;
$element-with-icon-side-padding:      $element-side-padding + $element-icon-size + $element-side-padding;
$element-border-color-focus:          $color-1-500;

$element-disabled-color:              $grey-1-100;
$element-disabled-bg:                 $grey-1-50;

$element-validation-error:            $support-error-500;
$element-validation-success:          $support-success-500;
$element-validation-info:             $grey-1-300;

@mixin validation-message-style {
    @include text-xs;
    margin-top: $spacing-2;
}

@mixin form-svg-base-styles {
    position: absolute;
    top: $spacing-3;
    width: $element-icon-size;
    height: $element-icon-size;
    color: $element-icon-color;
    z-index: 2;
    pointer-events: none; // Needed so you can click the icon and still trigger the select
}

.form-element {
    .element-wrapper {
        position: relative;

        .svg-icon {
            @include form-svg-base-styles();
        }

        .prefix-icon {
            left: $element-side-padding;
        }

        .suffix-icon {
            right: $element-side-padding;
        }

        &.has-prefix-icon {
            input {
                padding-left: $element-with-icon-side-padding;
            }
        }

        &.has-suffix-icon {
            input {
                padding-right: $element-with-icon-side-padding;
            }
        }

        input,
        textarea,
        select {
            @include transition(border-color);

            &:focus {
                @include transition(border-color, 200ms);
                border-color: $element-border-color-focus;
                outline: none;
            }
        }
    }

    &[class*="validation-"] {
        .validation-message {
            @include validation-message-style;
            display: flex;
            align-items: center;
        }

        .validation-message-icon {
            mask-position: center center;
            mask-repeat: no-repeat;
            width: $spacing-4;
            height: $spacing-4;
            display: inline-block;
            margin-right: $spacing-1;
        }
    }

    &.validation-error {
        :not([type="checkbox"]):not([type="radio"]),
        textarea,
        select {
            border-color: $element-validation-error;
        }

        // label,
        .validation-message {
            color: $element-validation-error;
        }

        .validation-message-icon {
            background: $element-validation-error;
            mask-image: url('/assets/icons/x-circle.svg');
        }
    }

    &.validation-success {
        :not([type="checkbox"]):not([type="radio"]),
        textarea,
        select {
            border-color: $element-validation-success;
        }

        // label,
        .validation-message {
            color: $element-validation-success;
        }

        .validation-message-icon {
            background: $element-validation-success;
            mask-image: url('/assets/icons/check-circle.svg');
        }
    }

    &.validation-info {
        .validation-message {
            color: $element-validation-info;
        }

        .validation-message-icon {
            background: $element-validation-info;
            mask-image: url('/assets/icons/alert-circle.svg');
        }
    }

    &.is-disabled {
        input,
        textarea,
        select {
            &:disabled {
                color: $element-disabled-color;
                background-color: $element-disabled-bg;
            }
        }

        .svg-icon {
            color: $element-disabled-color;
        }
    }
}
