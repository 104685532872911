@use '../../../1-core' as *;
@use '../../../1-core/FormElements/form-element-wrapper' as *;

.filtered-listing-toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: $spacing-6;

    .ais-SortBy {
        position: relative;

        &::after {
            content: '';
            @include form-svg-base-styles();
            background-color: $element-icon-color;
            mask-image: url('../assets/icons/chevron-down.svg');
            mask-position: center center;
            mask-repeat: no-repeat;
            mask-size: $spacing-6 $spacing-6;
            right: $element-side-padding;
        }
    }

    .ais-SortBy-select {
        padding-right: $element-with-icon-side-padding;
    }
}
