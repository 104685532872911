@use "../../sg-core" as *;

.sg-footer {
    border-top: $sg-base-border;
    padding-top: $sg-spacing-7;
    margin-top: $sg-spacing-9;
    margin-bottom: $sg-spacing-7;

    .container {
        overflow: hidden;

        //overrides
        .sg-sitemap {
            .sg-sitemap-column-header {
                @include sg-heading-sm;
            }

            a {
                @include sg-alt-link-style;
                display: block;
                line-height: $sg-leading-tight;

                &.is-current {
                    font-weight: 800;
                }
            }
        }
    }

    .logo-mark {
        width: $sg-spacing-6;
        height: $sg-spacing-6;
        display: block;
        float: right;
    }
}
