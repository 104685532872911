@use "../../sg-core/" as *;
@use "../../../1-core/typography/list-styles" as *;
@use "../../../1-core/typography/font-weights" as *;

.sg-sitemap {
    display: grid;
    grid-auto-flow: column;
    grid-gap: $sg-spacing-4;
    @include sg-typography;

    .sg-sitemap-column-header {
        margin-bottom: $sg-spacing-4;
    }

    ul {
        @include list-reset;

        li {
            margin-bottom: $sg-spacing-4;
        }
    }

    a[aria-current="page"] {
        @include text-bold;
        text-decoration: underline;
    }
}
