@use "../../sg-core" as *;

.sg-header {
    border-bottom: $sg-base-border;
    margin-bottom: $sg-base-spacing-mobile;

    .container {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding-top: $sg-spacing-4;
        padding-bottom: $sg-spacing-4;

        .client-logo {
            flex-shrink: 0;
        }

        .sg-header-title {
            @include sg-heading-lg;
            margin-left: $sg-spacing-4;
            margin-bottom: 0;
        }

        .sg-header-logo-mark {
            flex-shrink: 0;
            margin-left: auto;
            width: $sg-spacing-6;
            height: $sg-spacing-6;
            display: block;
        }
    }
}
