@use "../sg-core" as *;

.sg-eg-favicon {
    display: flex;
    align-items: flex-start;

    img {
        flex-grow: 0;
    }

    img:not(:last-of-type) {
        margin-right: $sg-spacing-6;
    }
}
