@use "sass:math";
@use "../sg-core" as *;
@use "../../1-core/typography/text-styles" as *;

.background-icon-change-colour {
    width: $sg-spacing-7;
    height: $sg-spacing-7;
    background: blue;
    mask-image: url('/assets/icons/chevron-up.svg');
    mask-position: center center;
    mask-repeat: no-repeat;
}

.sg-eg-icons {
    .icons-wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        grid-gap: $sg-spacing-6;

        > div {
            display: flex;
            align-items: center;

            .svg-icon {
                flex-shrink: 0;
                width: $sg-spacing-6;
                height: $sg-spacing-6;
                max-height: $sg-spacing-6;
                margin-right: math.div($sg-spacing-6, 2);
            }

            .icon-details {
                p {
                    margin-bottom: 0;
                }

                .icon-file-name {
                    @include text-sm;
                    line-height: $sg-leading-none;
                }
            }
        }
    }
}
