@use "../../1-core/" as *;

// Breadcrumbs
//----------------------------------------
$breadcrumb-margin:                 $spacing-3;

.breadcrumbs {
    display: flex;
    align-items: center;
    gap: $breadcrumb-margin;

    a {
        @include text-link;
    }

    .breadcrumb-separator {
        // styles go here
    }

    .breadcrumb-link {
        // styles go here
    }

    .breadcrumb-current-item {
        @include text-base;
    }
}

