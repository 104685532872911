@use "../../1-core/colours" as *;
@use "../../1-core/grids-breakpoints" as *;
@use "../../1-core/spacing-scale" as *;
@use "../../1-core/typography/text-styles" as *;

$mobile-header-height           : 70px;
$mobile-header-height-xs        : 130px;
$desktop-header-height          : 90px;
$mobile-content-min-height      : 760px;
$desktop-content-min-height     : 600px;
$homepage-content-margin-top    : -48px;


.homepage-content {
    min-height: max(#{$mobile-content-min-height}, 100vh);
    display: flex;
    position: relative;
    padding-top: $mobile-header-height;
    z-index: 1;

    .container {
        flex: 1;
        display: flex;
        flex-direction: column;
        position: relative;

        .text-block {
            text-align: center;

            .mobile-hide {
                display: none;
            }

            .desktop-hide {
                display: block;
            }

            h1 {
                @include text-4xl;
            }
        }

        .candle-block {
            grid-row: 1 / 3;
            flex: 1;

            .candle-display,
            .list-display {
                display: flex;
                justify-content: center;
                // height: 100%;
            }

            .candle-display {
                position: relative;
                justify-content: center;
                align-items: center;
                height: 100%;
            }

            .list-display {
                flex-direction: column;
                align-items: stretch;
            }

            & > .hidden {
                display: none;
            }
        }

        &.is-animation-active {
            overflow: hidden;
        }

        .mobile-instruction-link {
            color: $color-1-500;
            display: inline-flex;
            background-color: transparent;
            padding: 0;
            border: none;
            cursor: pointer;
            text-decoration: underline;

            &.hidden {
                display: none;
            }
        }
    }

    .locate-button {
        display: none;
        margin-top: $spacing-5;

        &.is-visible {
            display: block;
        }

        &:not(.has-my-star) {
            display: none;
        }
    }
}

.homepage-top-sticky {
    position: fixed;
    background-color: $color-1-500;
    right: 0;
    left: 0;
    bottom: 0;
    padding: $spacing-2;
    z-index: 5;

    .instruction-container {
        display: none;
    }
}


#instruction-modal {
    .modal-overlay {
        background: rgba($color: $dark-grey, $alpha: 0.8);
    }

    .modal-container {
        border-radius: 5px;
        border: 1px solid rgba(255, 255, 255, 0.2);
        background-color: $color-1-500;
        min-width: 300px;
        max-width: 90vw;
        max-height: 80vh;

        .modal-header {
            margin-bottom: 0;

            .modal-close {
                color: $white;
                width: 24px;
                height: 24px;
            }
        }

        h3,
        h6,
        p {
            margin: 0 0 $spacing-4;
        }

        h3 {
            @include text-lg;
        }

        p {
            @include text-base;
            color: $grey-1-100;
        }

        h6 {
            @include text-base;
        }

        .modal-content-close {
            padding: 0;
            background-color: transparent;
            border: none;
            color: $white;
            text-decoration: underline;
            cursor: pointer;

            &:hover {
                color: $color-2-500;
            }
        }
    }
}


@media (max-width: $screen-md-max) {
    .homepage-content {
        .container {
            padding-bottom: $spacing-8 + $spacing-2;
            padding-left: 0;
            padding-right: 0;

            .text-block {
                background-color: $white;
                padding: $spacing-4;
                margin-left: -50vw;
                margin-right: -50vw;
                left: 50%;
                right: 50%;
                position: relative;
                width: 100vw;

                h1 {
                    color: $color-1-500;
                    @include text-md;
                }

                p {
                    color: $grey-1-700;
                }
            }
        }

        .locate-button {
            margin-top: $spacing-4;
            color: $color-1-500;
        }
    }

    .homepage-top-sticky {
        .container {
            padding: 0 $spacing-2;
        }

        .homepage-control-block {
            display: flex;

            .luminary-credit {
                margin-left: auto;
            }
        }
    }
}

@media (min-width: $screen-xs-max) {
    .homepage-content {
        padding-top: $mobile-header-height;
    }
}

@media (min-width: $screen-lg) {
    .homepage-content {
        min-height: $desktop-content-min-height;
        padding-top: $desktop-header-height;
        margin-top: $homepage-content-margin-top;

        // &.fullpage {
        //     min-height: calc(100vh - #{$desktop-header-height});
        // }

        .container {
            padding-top: $spacing-6;
            @include grid();
            height: 100%;
            position: relative;

            .text-block {
                @include col(3);
                display: flex;
                flex-direction: column;
                gap: $spacing-4;
                text-align: left;

                .mobile-hide {
                    display: block;
                }

                .desktop-hide {
                    display: none;
                }
            }

            .candle-block {
                @include col(8, 4);

                &.is-animation-active {
                    @include col(6, 4);
                }

                .candle-display {
                    min-height: calc(100vh - (#{$desktop-header-height} + #{$spacing-9}));
                    justify-content: center;
                }

                .list-display {
                    margin-top: $spacing-8 + $spacing-2;
                    margin-bottom: $spacing-7;
                    justify-content: flex-start;
                }

                & > .hidden {
                    display: none;
                }
            }

            .mobile-instruction-link {
                display: none;
            }
        }
    }

    .homepage-top-sticky {
        position: sticky;
        top: $desktop-header-height;
        background-color: transparent;
        padding: $spacing-4 0 $spacing-2 $spacing-2;
        height: $spacing-8;
        // margin-top: $spacing-7;

        .container {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
        }

        &.stand-out {
            padding-top: 0;

            .homepage-control-block {
                padding: $spacing-4;
                background-color: $white;
                margin-right: -$spacing-4;
                border-bottom-left-radius: 5px;
                border-bottom-right-radius: 5px;
            }
        }

        .instruction-container {
            display: block;
            margin-top: $spacing-4;

            .instruction-link {
                color: $color-2-500;
                display: flex;
                background-color: transparent;
                padding: 0;
                border: none;
                cursor: pointer;
            }

            &.hidden {
                display: none;
            }
        }
    }

    .homepage-bottom-fixed {
        position: fixed;
        bottom: $spacing-8;
        left: 0;
        right: 0;
        z-index: 0;

        .container {
            @include grid;

            .info-block {
                @include col(3);
            }

            .luminary-credit {
                @include col(2, 10);
                align-self: flex-end;
                justify-self: flex-end;
            }
        }
    }

    #instruction-modal {
        .modal-container {
            background-color: $color-1-500;
            max-width: 750px;
            max-height: 100vh;

            .modal-header {
                margin-bottom: 0;

                .modal-close {
                    color: $white;
                    width: 24px;
                    height: 24px;
                }
            }

            h3,
            h6,
            p {
                margin: 0 $spacing-9 $spacing-4 0;
            }

            h3 {
                @include text-lg;
            }

            p {
                @include text-base;
                color: $grey-1-100;
            }

            h6 {
                @include text-base;
            }

            .modal-content-close {
                padding: 0;
                background-color: transparent;
                border: none;
                color: $white;
                text-decoration: underline;
                cursor: pointer;

                &:hover {
                    color: $color-2-500;
                }
            }
        }
    }
}

@media (min-width: $screen-xl) {
    .homepage-content {
        .container {
            .candle-block {
                .candle-display {
                    min-height: calc(100vh - (#{$desktop-header-height} + #{$spacing-12}));
                }
            }
        }
    }
}
