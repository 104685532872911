@use "../../1-core/colours" as *;
@use "../../1-core/grids-breakpoints" as *;
@use "../../1-core/spacing-scale" as *;
@use "../../3-components/Buttons/buttons" as *;

$mobile-header-height: 70px;

.mobile-header {
    background-color: $color-1-500;

    .container {
        display: flex;
        flex-wrap: wrap;
        padding: 0 $spacing-2;
        align-items: center;
        justify-content: space-between;
    }

    .header-logo {
        width: 162px;
    }

    .header-cta {
        padding: $spacing-4 0;

        .cta-container {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 16px;

            a {
                border-radius: $spacing-6;
                @include button-size('sm');
            }
        }

        .cta-text {
            color: $white;
        }
    }

    &.is-sticky {
        position: fixed;
        width: 100%;
        z-index: 2;
        height: $mobile-header-height;
    }
}

@media (min-width: $screen-sm) {
    .mobile-header {
        flex-wrap: nowrap;

        .container {
            padding-top: 0;
            justify-content: normal;
        }

        .header-cta {
            margin-left: auto;
        }
    }
}

@media (min-width: $screen-lg) {
    .mobile-header {
        display: none;
    }
}
