@use "../colours" as *;
@use "../animations" as *;
@use "text-styles" as *;

// Links
// =======================================================================
@mixin text-link {
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    color: $color-1-300;
    @include transition(box-shadow);
    box-shadow: 0 2px 0 $color-1-300;

    &:hover {
        color: $color-2-500;
        box-shadow: 0 0 0 $color-1-300;
        @include transition(box-shadow, 200ms);
    }
}

.text-link {
    @include text-link;
}
