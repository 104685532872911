@use "../../1-core/" as *;
@use "../../3-components/Buttons/buttons" as *;
@use "../../3-components/Image/caption" as *;

$video-embed-icon-size:         $spacing-11;
$video-play-primary-colour:     $color-1-500;
$video-play-secondary-colour:   $white;

.video-embed {
    position: relative;

    &.show-video {
        .video-embed-overlay,
        .video-embed-img-container {
            display: none;
        }
    }

    .video-embed-video-container {
        position: relative;
        padding-bottom: calc(100% / (var(--aspect-ratio)));
    }

    .video-embed-video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .video-embed-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .video-embed-play-btn {
        @include button-reset();
        display: inline-flex;
        position: relative;

        .svg-icon {
            @include transition(all);
            width: $video-embed-icon-size;
            height: $video-embed-icon-size;

            path:first-child {
                color: $video-play-primary-colour;
                fill-opacity: 1;
            }

            path:nth-child(2) {
                color: $video-play-secondary-colour;
            }
        }

        &::before {
            display: block;
            content: '';
            position: absolute;
            background-color: rgba($video-play-primary-colour, 0.3);
            border-radius: 50%;
            width: $video-embed-icon-size;
            height: $video-embed-icon-size;
            z-index: -1;
            transform: scale(0.8, 0.8);
            @include transition(all);
        }

        &:hover {
            .svg-icon,
            &::before {
                transform: scale(1.2, 1.2);
            }
        }
    }

    .video-caption {
        @include image-caption;
    }
}
