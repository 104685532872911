@use "../../1-core/colours" as *;
@use "./inputs" as *;

select {
    @include base-input-styles;
    background-color: $white;
    appearance: none;
    position: relative;

    option {
        color: $input-color;
    }
}
