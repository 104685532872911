@use "../../1-core" as *;
@use "./form-element-wrapper" as *;

$input-min-height:                  $spacing-8;
$input-padding:                     0 $element-side-padding;
$input-border-color:                $grey-1-100;
$input-radius:                      $spacing-1;
$input-color:                       $grey-1-500;
$input-bg:                          $white;

@mixin base-input-styles {
    @include text-base;
    -webkit-appearance: none;
    width: 100%;
    min-height: $input-min-height;
    padding: $input-padding;
    border: 1px solid $input-border-color;
    border-radius: $input-radius;
    color: $input-color;
    background-color: $input-bg;
    display: block;
}

input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]) {
    @include base-input-styles;
}
