@use "../sg-core" as *;
@use "../../1-core/elevation-shadows" as *;
@use "../../1-core/typography/text-styles" as *;

.sg-eg-elevation-shadows {
    .container {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
        grid-gap: $sg-spacing-8;
    }

    &.dark-example {
        background-color: $sg-grey-1-900;

        .shadow-example {
            background-color: $sg-grey-1-900;
        }
    }

    .shadow-example {
        display: block;
        min-width: 50px;
        width: 100%;
        height: 0;
        padding-bottom: 100%;
        background-color: $sg-white;
        position: relative;

        .eg-text {
            position: absolute;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            @include text-sm;
            color: $sg-color-shade-2;
            text-align: center;
        }

        @each $key, $value in $light-shadows {
            &.css-light-shadow-#{$key} {
                @include light-shadow($key);
            }
        }

        @each $key, $value in $color-1-shadows {
            &.css-color-1-shadow-#{$key} {
                @include color-1-shadow($key);
            }
        }

        @each $key, $value in $color-2-shadows {
            &.css-color-2-shadow-#{$key} {
                @include color-2-shadow($key);
            }
        }

        @each $key, $value in $dark-shadows {
            &.css-dark-shadow-#{$key} {
                @include dark-shadow($key);
            }
        }
    }
}
