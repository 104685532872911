@use "../../1-core/" as *;

$social-share-color:        $white;
$social-share-hit-area:     $spacing-7;
$social-share-icon-size:    20px;

.social-share {
    display: flex;
    align-items: center;
    position: relative;
    margin-top: $spacing-2;

    .social-share-intro {
        @include text-bold;
        line-height: 1;
        color: $social-share-color;
        margin-right: $spacing-2;
    }

    &.inherit-color .social-share-intro {
        color: inherit;
    }

    .social-share-intro,
    a,
    button {
        margin-right: $spacing-2;
    }

    a,
    button {
        height: $social-share-hit-area;
        min-width: $social-share-hit-area;
        color: $social-share-color;
        display: flex;
        justify-content: center;
        align-items: center;
        @include transition(color);
        margin: 0;

        &:hover,
        &:focus {
            @include transition(color, 200ms);
            color: $color-2-500;
        }
    }

    &.inherit-color {
        a,
        button {
            color: inherit;
        }
    }

    a {
        text-decoration: none;
    }

    button {
        background: none;
        border: 0;
        padding: 0;
        cursor: pointer;

        &::before {
            content: '';
            display: inline-block;
            height: $spacing-5;
            border: 1px solid $grey-1-100;
            margin-left: $spacing-2;
            margin-right: $spacing-4;
        }

        svg {
            height: $social-share-icon-size;
            width: $social-share-icon-size;
            margin-right: $spacing-2;
        }

        span {
            text-decoration: underline;
        }
    }

    .copied-link-text {
        @include text-sm;
        @include text-bold;
        line-height: 1;
        opacity: 0;
        visibility: hidden;
        transition: opacity ease-in-out 0.3s, visibility 0s 0.3s;
        color: $social-share-color;
        margin-left: $spacing-2;

        &.visible {
            opacity: 1;
            visibility: visible;
            transition: opacity ease-in-out 0.3s, visibility 0s;
        }
    }

    &.inherit-color .copied-link-text {
        color: inherit;
    }
}
