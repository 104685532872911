@use "spacing-scale" as *;

// Icons
//----------------------------------------
$icon-lg:  $spacing-6;
$icon-md:  $spacing-5;
$icon-sm:  $spacing-4;

@mixin icon-lg {
    width: $icon-lg;
    height: $icon-lg;
}

.icon-lg {
    @include icon-lg;
}

@mixin icon-md {
    width: $icon-md;
    height: $icon-md;
}

.icon-md {
    @include icon-md;
}

@mixin icon-sm {
    width: $icon-sm;
    height: $icon-sm;
}

.icon-sm {
    @include icon-sm;
}

// Base class
.svg-icon {
    @include icon-md;
}
