@use "sg-core" as *;

.sg-content {
    background-color: $sg-white;
    overflow: hidden;

    .container {
        @include sg-vertical-spacing;
        @include sg-typography;
    }

    + section[class*="s-"] {
        margin-top: $sg-spacing-9; //! By default project sections get a margin bottom. On the styleguide, give them a margin-top to match so they look nice in the docs.

        &.no-margin-top {
            margin-top: 0;
        }

        &.no-margin-bottom {
            margin-bottom: 0;
        }
    }
}
