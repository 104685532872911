@use '../../../1-core' as *;
@use '../../../3-components/Buttons/buttons' as *;
@use '../../../1-core/typography/list-styles' as *;

.filtered-listing-active-refinements {
    display: flex;
    justify-content: space-between;

    .ais-CurrentRefinements:not(.ais-CurrentRefinements--noRefinement) {
        margin-bottom: $spacing-5;
    }


    .ais-CurrentRefinements-list {
        @include list-reset;

        .ais-CurrentRefinements-item {
            display: flex;
            align-items: center;

            span:first-of-type {
                margin-right: $spacing-3;
            }
        }
    }

    .ais-CurrentRefinements-category {
        display: flex;
        align-items: center;
        @include text-sm;
        background-color: $color-1-500;
        padding: 0 $spacing-2;
        border-radius: 4px;

        &:not(:last-of-type) {
            margin-right: $spacing-2;
        }

        .ais-CurrentRefinements-delete {
            @include button-skin('ghost');
            @include button-size('sm', true);
        }
    }

    .ais-ClearRefinements-button {
        @include button-skin('ghost');
        @include button-size('sm');

        &.ais-ClearRefinements-button--disabled {
            display: none;
        }
    }
}
