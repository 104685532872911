@use "../../1-core/" as *;

.image-and-text {
    .content-wrapper {
        @include rich-text-spacing;
    }
}

@media (max-width: $screen-sm-max) {
    .image-and-text {
        .media-wrapper {
            margin-bottom: $spacing-5;
        }
    }
}

@media (min-width: $screen-md) {
    .image-and-text {
        .container {
            @include grid;
            grid-row-gap: $spacing-5;

            .media-wrapper,
            .content-wrapper {
                @include col(6, 1);
            }
        }
    }
}

@media (min-width: $screen-lg) {
    .image-and-text {
        .container {
            @include grid;

            .media-wrapper,
            .content-wrapper {
                @include col(6);
            }
        }

        &.reverse-layout {
            .media-wrapper {
                order: 2;
            }
        }

        &.vertically-center .container {
            align-items: center;
        }
    }
}
