@use "../../1-core/colours" as *;
@use "../../1-core/spacing-scale" as *;
@use "./donation-list-item-story";
@use "./donation-list-item-donation";
@use "./donation-list-my-star";

.donation-list-container {
    & ~ .luminary-credit {
        justify-content: center;
        margin-top: $spacing-7;
        padding: $spacing-5 0 0;
        border-top: 1px solid $color-1-100;
    }
}

.story-open-button {
    display: flex;

    &:hover {
        &::after {
            content: '';
            background: $color-2-500;
            mask-image: url('/assets/icons/arrow-right.svg');
            mask-position: center center;
            mask-repeat: no-repeat;
            width: $spacing-5;
            height: $spacing-5;
            display: inline-block;
            margin-left: $spacing-1;
        }
    }
}
