@use "../../sg-core" as *;
@use "../../../1-core/typography/font-weights" as *;

.sg-info-box {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-radius: $sg-spacing-2;
    padding: $sg-spacing-5;
    margin: $sg-spacing-6 0;

    .info-box-text {
        @include sg-vertical-spacing;
        color: $sg-grey-1-700;
    }

    .svg-icon {
        flex-shrink: 0;
        width: $sg-spacing-7;
        height: $sg-spacing-7;
    }

    &.blue {
        background: $info-box-blue-100;

        .svg-icon,
        .info-box-text h3 {
            color: $info-box-blue-500;
        }
    }

    &.yellow {
        background: $info-box-yellow-100;

        .svg-icon,
        .info-box-text h3 {
            color: $info-box-yellow-500;
        }
    }

    &.green {
        background: $info-box-green-100;

        .svg-icon,
        .info-box-text h3 {
            color: $info-box-green-500;
        }
    }

    &.red {
        background: $info-box-red-100;

        .svg-icon,
        .info-box-text h3 {
            color: $info-box-red-500;
        }
    }
}
