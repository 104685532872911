@use "../../1-core/" as *;
@use 'alignments' as *;

@forward 'caption';

$image-loading-bg:              $color-1-500;

.image-wrapper {
    overflow: hidden;
    position: relative;
    width: var(--image-width);
    height: var(--image-height);
    max-width: 100%;

    figure {
        width: 100%;
        height: 100%;

        picture {
            width: auto;
            height: auto;
            background-color: $color-1-500;

            img {
                position: relative;
                z-index: 1; //sits image above loading animation
                @include object-align(var(--image-alignment), var(--image-object-fit));
            }

            &.has-ratio {
                height: 0;
                width: 100%;
                padding-bottom: calc(100% / (var(--aspect-ratio)));
                position: relative;

                img {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}
