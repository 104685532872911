@use "../../1-core/typography/type-mixins" as *;
@use "../../1-core/typography/font-stacks" as *;
@use "../../1-core/typography/font-weights" as *;
@use "../../1-core/typography/list-styles" as *;
@use "sg-colours" as *;
@use "spacing/sg-spacing-scale" as *;

$font-path: '../assets/styleguide/fonts';

// Name, filename, svgId, weight (optional)
@include fontFace('SpaceMono', 'spacemono-regular-webfont', 400, normal);
@include fontFace('SpaceMono', 'spacemono-bold-webfont', 700, normal);

// Custom font stack (w/ websafe fallback)
$sg-font-mono: 'SpaceMono', $font-mono;

$sg-size-12: rem-font-size(12px);
$sg-size-14: rem-font-size(14px);
$sg-size-16: rem-font-size(16px);
$sg-size-18: rem-font-size(18px);
$sg-size-20: rem-font-size(20px);
$sg-size-24: rem-font-size(24px);
$sg-size-30: rem-font-size(30px);
$sg-size-36: rem-font-size(36px);
$sg-size-48: rem-font-size(48px);
$sg-size-60: rem-font-size(60px);
$sg-size-72: rem-font-size(72px);

$sg-leading-none: 1;
$sg-leading-tight: 1.25;
$sg-leading-snug: 1.375;
$sg-leading-normal: 1.5;
$sg-leading-relaxed: 1.625;
$sg-leading-loose: 2;

$sg-base-border: 1px solid $sg-color-shade-3;

$sg-font-grey: $sg-grey-1-800;
$ol-dot-color: $sg-color-primary-2;
$ul-dot-color: $sg-color-primary-3;

@mixin sg-base-heading-styles {
    font-family: $font-sans;
    @include text-semibold;
    color: $sg-black;
}

@mixin sg-heading-xl {
    @include sg-base-heading-styles;
    font-size: $sg-size-36;
    line-height: $sg-leading-tight;
}

@mixin sg-heading-lg {
    @include sg-base-heading-styles;
    font-size: $sg-size-24;
    line-height: $sg-leading-snug;
}

@mixin sg-heading-md {
    @include sg-base-heading-styles;
    font-size: $sg-size-18;
    line-height: $sg-leading-normal;
}

@mixin sg-heading-sm {
    @include sg-base-heading-styles;
    font-size: $sg-size-16;
    line-height: $sg-leading-normal;
}

@mixin sg-text-base {
    font-family: $font-sans;
    color: $sg-font-grey;
    font-size: $sg-size-16;
    line-height: $sg-leading-normal;
}

@mixin sg-base-link-style {
    font-size: $sg-size-16;
    line-height: $sg-leading-normal;
    color: $sg-color-accent-1;
    text-decoration: none;

    &:hover,
    &:focus {
        color: $sg-color-accent-hover-1;
        text-decoration: underline;
    }
}

@mixin sg-alt-link-style {
    @include sg-base-link-style;
    font-family: $sg-font-mono;
    font-size: $sg-size-12;
    line-height: $sg-leading-loose;
}

@mixin sg-code-style {
    padding: $sg-spacing-2;
    font-family: $sg-font-mono;
    font-size: $sg-size-14;
    color: $sg-color-primary-2;
    background-color: $sg-grey-1-200;
    border-radius: $sg-spacing-2;
}

@mixin sg-typography {
    .sg-heading-xl,
    h1 {
        @include sg-heading-xl;
    }

    .sg-heading-lg,
    h2 {
        @include sg-heading-lg;
    }

    .sg-heading-md,
    h3 {
        @include sg-heading-md;
    }

    .sg-heading-sm,
    h4,
    h5,
    h6 {
        @include sg-heading-sm;
    }

    p,
    ol,
    ul,
    ol li,
    ul li {
        @include sg-text-base;
    }

    a {
        @include sg-base-link-style;
    }

    p > code,
    ul li code,
    ol li code,
    strong > code,
    em > code {
        @include sg-code-style;
    }

    code[class*="language-"],
    pre[class*="language-"] {
        font-size: $sg-size-16;
        line-height: $sg-leading-normal;
    }
}
