@use '../../../1-core/typography/list-styles' as *;

.filtered-listing-filters {
    .ais-RefinementList-list {
        @include list-reset;
        min-width: 200px;

        .ais-RefinementList-label {
            display: flex;
            justify-content: flex-start;

            .ais-RefinementList-count {
                margin-left: auto;
            }
        }
    }
}
