@use "../../1-core/colours" as *;
@use "../../1-core/grids-breakpoints" as *;
@use "../../1-core/spacing-scale" as *;
@use "../../1-core/typography/font-weights" as *;

$desktop-logo-width: 300px;
$desktop-header-height: 90px;

.desktop-header {
    background-color: $color-1-500;
    height: $desktop-header-height;
    z-index: 1;

    .container {
        @include grid();
        padding-top: $spacing-2;
        padding-bottom: $spacing-2;

        .header-logo {
            @include col(4);
            display: flex;
            align-items: center;

            a {
                display: block;
            }

            img {
                width: $desktop-logo-width;
            }
        }

        .header-nav {
            @include col(4);
            display: flex;
            align-items: center;
            justify-content: center;

            ul {
                display: flex;
                gap: 16px;
                margin-left: 16px;
            }

            ul li {
                list-style: none;
                margin: 0;
                position: relative;
                padding-left: 0;

                a {
                    text-decoration: none;
                    color: $white;

                    &:hover {
                        color: $color-2-500;
                    }
                }

                &::before {
                    content: none;
                }

                &.active {
                    a {
                        color: $color-2-500;
                        @include text-bold;
                    }
                }

                &.active::after {
                    content: '';
                    position: absolute;
                    height: 4px;
                    width: 100%;
                    bottom: 0;
                    left: 0;
                    background-color: $color-2-500;
                    transform: translateY(8px);
                    border-radius: 2px;
                }
            }

            p {
                @include text-bold;
            }
        }

        .header-cta {
            @include col(4);
            display: flex;
            justify-content: right;

            .cta-container {
                display: flex;
                align-items: center;

                .cta-text {
                    margin-right: 16px;
                }

                a {
                    border-radius: $spacing-6;
                }
            }

            .cta-text {
                @include text-bold;
            }
        }
    }

    &.is-sticky {
        height: $desktop-header-height;
        position: fixed;
        width: 100%;
        z-index: 2;
    }
}

@media (max-width: $screen-md-max) {
    .desktop-header {
        display: none;
    }
}
