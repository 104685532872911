@use '../../1-core/typography/list-styles' as *;

.animation {
    contain: size layout;
    position: absolute;
    width: 100%;
    height: 100%;

    &.pause-container {
        contain: inherit;
        position: static;
        width: auto;
        height: auto;
    }

    &.is-panning-active {
        cursor: grab;
    }

    &.is-panning {
        cursor: grabbing;
    }

    .animation-pause {
        position: absolute;
        top: 18px;
        right: 20px;
        z-index: 1;
    }

    .animation-list {
        @include list-container-reset;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        perspective: 100vh;
        transform-style: preserve-3d;
        pointer-events: none;
    }
}

