@use "../../1-core/" as *;

// Back to top anchor
//----------------------------------------
$back-to-top-width:                  $spacing-9;
$back-to-top-bg:                     $color-1-500;
$back-to-top-hover-bg:               $grey-1-300;
$back-to-top-right-margin:           $spacing-4;
$back-to-top-bottom-margin:          $spacing-4;
$back-to-top-icon-width:             $spacing-6;
$back-to-top-icon-color:             $white;
$back-to-top-icon-height:            $spacing-6;

.back-to-top {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    right: $back-to-top-right-margin;
    bottom: $back-to-top-bottom-margin;
    width: $back-to-top-width;
    height: $back-to-top-width;
    border-radius: 50%;
    overflow: hidden;
    visibility: hidden;
    opacity: 0;
    cursor: pointer;
    background-color: $back-to-top-bg;
    transition:
        opacity 400ms ease 0s,
        visibility 400ms ease 0s,
        background-color 400ms ease 0s;

    .svg-icon {
        width: $back-to-top-icon-width;
        height: $back-to-top-icon-height;
        color: $back-to-top-icon-color;
    }

    &.is-visible {
        transition:
            opacity 200ms ease 0s,
            visibility 200ms ease 0s,
            background-color 200ms ease 0s;
        opacity: 1;
        visibility: visible;
        z-index: 1;

        &:hover,
        &:focus {
            background-color: $back-to-top-hover-bg;
        }
    }
}
