@use '../../1-core' as *;

.animation-pause-button {
    $icon-size: 27px;
    position: relative;
    display: flex;
    align-items: center;
    background: none;
    border: 0 none;
    padding: 0;
    color: $white;
    cursor: pointer;

    .animation-pause-button-icon {
        margin-right: $spacing-2;
        width: $icon-size;
        height: $icon-size;

        circle {
            fill: white;
        }

        path {
            fill: $color-1-500;
        }

        path[stroke-width="2"] {
            stroke: $color-1-500;
        }
    }

    .animation-pause-button-display-text {
        position: absolute;
        left: $icon-size + $spacing-2;
    }

    .animation-pause-button-width-text {
        visibility: hidden;
    }
}
