@use '../../1-core' as *;
@use '../../3-components/Buttons/buttons' as *;
@use '../../1-core/FormElements/form-element-wrapper' as *;

.ais-SearchBox-form {
    position: relative;

    .ais-SearchBox-input {
        padding-right: $element-with-icon-side-padding;
    }

    .ais-SearchBox-submit {
        @include button-skin('ghost');
        position: absolute;
        top: $spacing-2;
        right: $element-side-padding;
        display: flex;
        align-items: center;
        justify-content: center;

        .svg-icon {
            width: $spacing-6;
            height: $spacing-6;
        }
    }

    .ais-SearchBox-reset {
        @include button-skin('ghost');
        position: absolute;
        top: $spacing-2;
        right: $element-side-padding + $spacing-7;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: $spacing-1;

        &[hidden] {
            display: none;
        }
    }
}
