@use '../../1-core' as *;
@use '../../1-core/typography/list-styles' as *;

@forward './widgets/active-refinements';
@forward './widgets/toolbar';
@forward './widgets/refinements';
@forward './widgets/custom-hit';
@forward './widgets/no-result';

.filtered-listing {
    .filtered-listing-searchbar {
        margin-bottom: $spacing-5;
    }

    .filtered-listing-filters-and-results-wrapper {
        display: grid;
        grid-template-columns: auto 1fr;
        gap: $spacing-8;

        .filtered-listing-results {
            display: flex;
            flex-direction: column;

            .ais-Hits {
                margin-bottom: $spacing-5;

                .ais-Hits-list {
                    @include list-reset;
                    @include three-col-grid;
                }
            }

            .ais-Pagination {
                align-self: center;
            }
        }
    }
}

