@use "../../1-core/colours" as *;
@use "../../1-core/grids-breakpoints" as *;
@use "../../1-core/spacing-scale" as *;
@use "../../1-core/typography/text-styles" as *;
@use "../../1-core/typography/font-weights" as *;
@use "../../3-components/Buttons/buttons" as *;

$story-modal-min-width-mobile   : 300px;
$story-modal-max-width-desktop  : 1153px;

.story-modal {
    outline: none;

    .modal-close span.story-modal-close-text {
        @include text-bold;
    }

    &.modal-container,
    .modal-container {
        min-width: $story-modal-min-width-mobile;
        margin: $spacing-4;
        border-radius: 5px;
        position: relative;
        max-height: 85vh;
    }

    .story-modal-block {
        display: flex;
        flex-direction: column;

        .story-modal-image-container,
        .story-modal-intro {
            flex: 1;
            display: flex;
            flex-direction: column;
        }

        .story-modal-image {
            width: 100%;
            object-fit: cover;
        }

        .story-modal-link {
            display: inline-flex;
            margin-left: $spacing-1;
        }

        .modal-donate-cta {
            margin-top: $spacing-6;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background-color: $color-2-100;
            padding: $spacing-5 $spacing-5 $spacing-6;
            text-align: center;

            h3 {
                color: $color-1-500;
                @include text-md;
                margin-bottom: $spacing-3;
            }
        }

        .story-cta-button {
            border-radius: $spacing-6;
            color: $color-1-500;
            box-shadow: none;
            @include button-size('md');

            &:hover {
                color: $white;
            }
        }

        .story-modal-intro {
            padding: $spacing-6 $spacing-4;

            h2 {
                color: $color-1-500;
                @include text-3xl;
                margin-bottom: $spacing-4;
            }

            p {
                color: $grey-1-700;
            }
        }
    }
}

@media (min-width: $screen-lg) {
    .story-modal {
        &.modal-container,
        .modal-container {
            max-width: $story-modal-max-width-desktop;
        }

        .story-modal-block {
            flex-direction: row;
            // gap: $spacing-6;

            .story-modal-image {
                height: 100%;
            }

            .story-modal-intro {
                padding: $spacing-9 $spacing-11;

                h2 {
                    @include text-4xl;
                }
            }

            .modal-donate-cta {
                h3 {
                    @include text-lg;
                    margin-bottom: $spacing-4;
                }
            }
        }
    }
}
