@use "../../1-core/" as *;

// Tables
$table-heading-bg:      $color-1-500;
$table-heading-color:   $white;

$table-row-bg:          $white;
$table-row-alt-bg:      $grey-1-100;
$table-row-hover-bg:    $grey-1-300;

$table-cell-padding:    $spacing-5;
$table-cell-color:      $grey-1-500;

.table-wrapper {
    display: block;
    width: 100%;
    overflow-x: auto;
}

table {
    width: 100%;
    max-width: 100%;
    border-collapse: separate; // border radius not working correctly with border if not using seperate
    border-spacing: 0;

    th,
    tr:first-of-type td {
        padding: $table-cell-padding;
        background-color: $table-heading-bg;
        text-align: left;
        @include text-xs;
        color: $table-heading-color;
    }

    tr:not(:first-of-type) {
        background-color: $table-row-bg;

        &:nth-child(odd) {
            background-color: $table-row-alt-bg;
        }

        td {
            // @include heading-label;
            padding: $table-cell-padding;
            color: $table-cell-color;
            border-left: 1px solid $grey-1-300;
            border-top: 1px solid $grey-1-300;
            @include text-sm;

            &:last-child {
                border-right: 1px solid $grey-1-300;
            }
        }

        &:hover {
            background-color: $table-row-hover-bg;

            td {
                border-color: $grey-1-300;
                border-bottom: 1px solid $grey-1-300;
            }
        }
    }

    tr:last-child {
        td {
            border-bottom: 1px solid $grey-1-300;
        }
    }
}
