@use "sg-core" as *;

.sg-page-content {
    padding-top: 0;

    section[class*='sg-'] {
        a {
            @include sg-base-link-style;
        }
    }
}
