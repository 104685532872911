@use "sg-spacing-scale" as *;

// Values based on https://www.gridlover.net/try
@mixin sg-vertical-spacing {
    > *:first-child {
        margin-top: 0;
    }

    > h1 {
        margin-bottom: $sg-spacing-6;
    }

    > h2 {
        margin-top: $sg-spacing-6;
        margin-bottom: $sg-spacing-4;
    }

    > h3 {
        margin-top: $sg-spacing-6;
        margin-bottom: $sg-spacing-3;
    }

    > h4,
    > h5,
    > h6 {
        margin-top: $sg-spacing-4;
        margin-bottom: $sg-spacing-2;
    }

    > p,
    > ul,
    > ol,
    > pre,
    > table,
    code[class*="language-"],
    pre[class*="language-"] {
        margin-top: 0;
        margin-bottom: $sg-spacing-4;
    }

    > ul ul,
    > ol ol,
    > ul ol,
    > ol ul {
        margin-top: $sg-spacing-4;
        margin-bottom: 0;
    }

    > ul li,
    > ul ul li,
    > ol li,
    > ol ol li {
        margin-bottom: $sg-spacing-3;
    }

    > *:first-child {
        margin-top: 0;
    }
}

// Globally set SECTION padding and margins.
$sg-base-spacing-mobile:        $sg-spacing-9;
$sg-base-spacing-tablet:        $sg-spacing-10;
$sg-base-spacing-desktop:       $sg-spacing-10;
