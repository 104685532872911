@use "../../1-core" as *;

.animation-locate-button {
    @include text-base;
    position: relative;
    display: inline-flex;
    padding: 5px 24px;
    align-items: center;
    justify-content: center;
    color: inherit;
    border-radius: 36px;
    border: 0 none;
    background: none;
    cursor: pointer;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 36px;
        border: 1px solid currentColor;
        pointer-events: none;
        opacity: 0.5;
    }
    // ^ A workaround to set an opacity but allow to inherit the colour from the parent elments.

    .animation-locate-button-icon {
        margin-right: $spacing-3;
        width: $spacing-4;
    }
}

@media (min-width: $screen-md) {
    .animation-locate-button {
        padding: 10px 30px;

        &::before {
            border-width: 2px;
            opacity: 1;
        }
    }
}
