@use "../../1-core/colours" as *;
@use "../../1-core/grids-breakpoints" as *;
@use "../../1-core/spacing-scale" as *;
@use "../../1-core/typography/text-styles" as *;
@use "../../1-core/grids-breakpoints" as *;

$donation-list-item-donation-image-size           : 96px;
$donation-list-item-donation-image-padding        : 6px;
$donation-list-item-donation-image-filter         : drop-shadow(0 0 16px rgba(246, 218, 73, 0.4));

.donation-list-item-donation {
    display: flex;
    gap: $spacing-4;
    padding: $spacing-4;
    border-bottom: 1px solid $color-1-100;
    align-items: flex-start;
    margin-bottom: 0;

    &::before {
        display: none;
    }

    .donation-list-image {
        width: $donation-list-item-donation-image-size;
        height: $donation-list-item-donation-image-size;
        object-fit: contain;
        padding: $donation-list-item-donation-image-padding;
        filter: $donation-list-item-donation-image-filter;
    }

    .donation-list-info {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        // padding-right: $spacing-4;
    }

    .donation-list-intro {
        flex: 1;
    }

    .intro-title {
        @include text-md;
        // @include text-2xl;
        margin-bottom: $spacing-2;
    }

    .donation-amount {
        background-color: $color-1-500;
        min-width: 160px;
        text-align: center;
        padding: $spacing-1 $spacing-4;
        border-radius: $spacing-1;
        margin-top: $spacing-2;

        .donation-total {
            color: $white;
        }

        span {
            display: inline-block;
            color: $grey-1-100;
            margin-left: $spacing-1;

            &:first-child {
                margin-left: 0;
            }
        }
    }
}

@media (min-width: $screen-lg) {
    .donation-list-item-donation {
        padding: $spacing-4 0;

        .donation-list-info {
            padding-right: 0;
            flex-direction: row;
            gap: $spacing-4;
        }

        .donation-amount {
            margin: 0;
            padding: $spacing-3 $spacing-4;

            span {
                display: block;
                color: $grey-1-100;
                margin: 0;
            }
        }

        .intro-title {
            @include text-lg;
        }
    }
}
