@use "../../1-core/colours" as *;
@use "../../1-core/grids-breakpoints" as *;
@use "../../1-core/spacing-scale" as *;
@use "../../1-core/typography/text-styles" as *;

$donation-target-info-border-radius : 5px;

.donation-target-info-box {
    padding: $spacing-4 $spacing-5;
    border-radius: $donation-target-info-border-radius;
    background-color: rgba($color: $color-1-500, $alpha: 1);

    h2 {
        @include text-4xl;
    }
}

@media (max-width: $screen-md-max) {
    .donation-target-info-box.mobile-hide {
        display: none;
    }
}
