// Visual helpers
//----------------------------------------
@mixin visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: none;
}

.sr-only {
    @include visually-hidden;
}
