@use "../../1-core/colours" as *;

$placeholder-color: $grey-1-100;

::placeholder {
    color: $placeholder-color;
}

select:invalid {
    color: $placeholder-color;
}
