@use "sass:math";
@use "../sg-core" as *;
@use "../../1-core/grids-breakpoints" as *;
@use "../../1-core/typography/text-styles" as *;

$eg-container-bg:        azure;
$eg-container-margin-bg: lavenderblush;
$eg-col-bg:              lavender;
$eg-item-bg:              aquamarine;

.eg-grid {
    @include grid;
    grid-row-gap: $sg-spacing-5;
    position: relative;
    padding-top: $sg-spacing-7;
    padding-bottom: $sg-spacing-6;

    .eg-item {
        background-color: $eg-item-bg;
        padding: $sg-spacing-5 $sg-spacing-3;
        text-align: center;
        z-index: 2;
        position: relative;
        @include text-sm;
        @include col(1);
    }

    @for $i from 1 through (12) {
        .eg-item-#{$i} {
            @media (max-width: $screen-xs-max) {
                @if $i >= 10 {
                    @include col($grid-cols-xs);
                }

                @if $i >= 7 and $i < 10 {
                    @include col(math.div($grid-cols-xs, 4) * 3);
                }

                @if $i >= 4 and $i < 7 {
                    @include col(math.div($grid-cols-xs, 4) * 2);
                }

                @if $i <= 3 {
                    @include col(math.div($grid-cols-xs, 4));
                }
            }

            @media (min-width: $screen-sm) and (max-width: $screen-md-max) {
                @if $i >= 10 {
                    @include col($grid-cols-sm);
                }

                @if $i >= 7 and $i < 10 {
                    @include col(math.div($grid-cols-sm, 4)* 3);
                }

                @if $i >= 4 and $i < 7 {
                    @include col(math.div($grid-cols-sm, 4)* 2);
                }

                @if $i <= 3 {
                    @include col(math.div($grid-cols-sm, 4));
                }
            }

            @media (min-width: $screen-lg) {
                @include col($i);
            }
        }
    }

    .eg-item-offset-1 {
        @include col(4, 2);
    }

    .eg-item-offset-2 {
        @include col(4, 7);
    }

    .eg-item-offset-3 {
        @include col(6, 3);
    }

    .eg-container-bleed {
        @include col(12);
        @include container-bleed;
    }

    .eg-full-bleed {
        @include col(12);
        @include full-bleed;
    }
}

.wrapper {
    position: relative;
}

.show-column-bg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    @include grid;

    .eg-column {
        background-color: rgba($eg-col-bg, 0.8);
        grid-row: 1 / -1;
        min-height: 200px;
    }
}

.eg-container {
    background-color: rgba($eg-container-bg, 0.8);
    min-height: 200px;
    position: relative;

    &::after,
    &::before {
        content: '';
        height: 100%;
        position: absolute;
        top: 0;
        background-color: $eg-container-margin-bg;
    }

    &::before {
        left: 0;
    }

    &::after {
        right: 0;
    }

    @media (min-width: 0) and (max-width: $screen-xs-max) {
        &::after,
        &::before {
            width: $container-padding-xs;
        }
    }

    @media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
        &::after,
        &::before {
            width: $container-padding-sm;
        }
    }

    @media (min-width: $screen-md) and (max-width: $screen-md-max) {
        &::after,
        &::before {
            width: $container-padding-md;
        }
    }

    @media (min-width: $screen-lg) and (max-width: $screen-lg-max) {
        &::after,
        &::before {
            width: $container-padding-lg;
        }
    }

    @media (min-width: $screen-xl) and (max-width: $screen-xl-max) {
        &::after,
        &::before {
            width: $container-padding-xl;
        }
    }

    @media (min-width: $screen-2xl) {
        &::after,
        &::before {
            width: $container-padding-2xl;
        }
    }
}

@media (max-width: $screen-xs-max) {
    .eg-grid {
        .col:nth-of-type(n+5),
        .eg-column:nth-of-type(n+5) {
            display: none;
        }
    }
}

@media (min-width: $screen-sm) and (max-width: $screen-md-max) {
    .eg-grid {
        .col:nth-of-type(n+9),
        .eg-column:nth-of-type(n+9) {
            display: none;
        }
    }
}

.eg-sub-grid {
    padding-top: $sg-spacing-6;
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 0;
    background-color: rgba(lightpink, 0.7);
    @include col(8, 2);
    @include subgrid(8);
    grid-row-gap: $sg-spacing-5;
    z-index: 1;

    .eg-sub-grid-offset-1 {
        @include col(3, 1);
    }

    .eg-sub-grid-offset-2 {
        @include col(3, 5);
    }

    > div {
        background-color: mistyrose;
    }
}
