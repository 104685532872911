@use "../sg-core" as *;
@use "../../1-core/grids-breakpoints" as *;

.sg-eg-btns {
    background-color: rgba($sg-color-shade-4, 0.5);

    .container {
        @include four-col-grid;
        gap: $sg-spacing-5;
    }

    .button-group {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .button-group-header {
            margin-bottom: $sg-spacing-4;

            &:not(:first-of-type) {
                margin-top: $sg-spacing-6;
            }
        }

        > a:not(:last-child),
        > button:not(:last-child) {
            margin-bottom: $sg-spacing-5;
        }
    }

    .custom-class {
        background: $sg-color-primary-3;
    }
}

.sg-eg-links {
    .container {
        @include three-col-grid;
        gap: $sg-spacing-5;
    }

    .button-group {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .button-group-header {
            margin-bottom: $sg-spacing-4;

            &:not(:first-of-type) {
                margin-top: $sg-spacing-6;
            }
        }

        > a:not(:last-child),
        > button:not(:last-child) {
            margin-bottom: $sg-spacing-5;
        }
    }

    .custom-class {
        background: $sg-color-primary-3;
    }
}
