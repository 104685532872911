@use "../../1-core/" as *;

.rich-text-block {
    .rich-text-block-content {
        @include rich-text-spacing;
    }
}

@media (min-width: $screen-md) {
    .rich-text-block {
        .container {
            @include grid;
        }
    }
}

@media (min-width: $screen-md) and (max-width: $screen-md-max) {
    .rich-text-block {
        .rich-text-block-content {
            @include col(6, 1);
        }
    }
}

@media (min-width: $screen-lg) {
    .rich-text-block {
        .rich-text-block-content {
            @include col(8, 2);
        }
    }
}
