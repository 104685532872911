@use "../sg-core" as *;
@use "../../1-core/colours" as *;

$color-width:   $sg-spacing-10;
$color-height:  $sg-spacing-8;

.sg-eg-colours {
    display: grid;
    grid-gap: $sg-spacing-6;
    grid-template-columns: 1fr 1fr 1fr;
    margin-bottom: $sg-spacing-9;

    .colour-swatch {
        h3 {
            margin-bottom: $sg-spacing-3;
        }

        .colour {
            height: $color-height;
            display: flex;
            align-items: center;

            &:first-of-type {
                border-radius: 6px 6px 0 0;
            }

            &:last-of-type {
                border-radius: 0 0 6px 6px;
            }


            span {
                padding: $sg-spacing-3 $sg-spacing-3 $sg-spacing-2;
                background-color: $sg-white;
                line-height: $sg-leading-none;
                margin-left: $sg-spacing-3;
                border-radius: 6px;
            }
        }
    }

    &.eg-support {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    &.eg-social {
        grid-gap: $sg-spacing-5;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    }

    &.eg-bg-themes {
        grid-gap: $sg-spacing-7;
        grid-template-columns: 1fr 1fr;

        .colour {
            .eg-colour {
                padding-top: $color-height * 5;
            }
        }
    }
}

// Styleguide reference colours
@each $primary, $color-var in $primary-colors {
    .bg-#{$primary} {
        background-color: #{$color-var};
    }
}

@each $shade, $color-var in $shades {
    .bg-#{$shade} {
        background-color: #{$color-var};
    }
}

@each $alert, $color-var in $alerts {
    .bg-#{$alert} {
        background-color: #{$color-var};
    }
}

.bg-white {
    border: 1px solid $grey-1-50;
    border-bottom: none;
}

.bg-black {
    border-top: 1px solid $grey-1-700;
}

@mixin white-opacity($opacity: 0.1) {
    background-color: rgba($white, $opacity);
}

@mixin black-opacity($opacity: 0.1) {
    background-color: rgba($black, $opacity);
}

.eg-opacity-bg {
    background-image: linear-gradient(45deg, $grey-1-100 25%, transparent 25%), linear-gradient(-45deg, $grey-1-100 25%, transparent 25%), linear-gradient(45deg, transparent 75%, $grey-1-100 75%), linear-gradient(-45deg, transparent 75%, $grey-1-100 75%);
    background-size: 20px 20px;
    background-position: 0 0, 0 10px, 10px -10px, -10px 0;

    > div {
        border-bottom: 1px solid $grey-1-100;
    }
}

.bg-white-opacity-10 {
    @include white-opacity(0.1);
}

.bg-white-opacity-20 {
    @include white-opacity(0.2);
}

.bg-white-opacity-30 {
    @include white-opacity(0.3);
}

.bg-white-opacity-40 {
    @include white-opacity(0.4);
}

.bg-white-opacity-50 {
    @include white-opacity(0.5);
}

.bg-white-opacity-60 {
    @include white-opacity(0.6);
}

.bg-white-opacity-70 {
    @include white-opacity(0.7);
}

.bg-white-opacity-80 {
    @include white-opacity(0.8);
}

.bg-white-opacity-90 {
    @include white-opacity(0.9);
}

.bg-black-opacity-10 {
    @include black-opacity(0.1);
}

.bg-black-opacity-20 {
    @include black-opacity(0.2);
}

.bg-black-opacity-30 {
    @include black-opacity(0.3);
}

.bg-black-opacity-40 {
    @include black-opacity(0.4);
}

.bg-black-opacity-50 {
    @include black-opacity(0.5);
}

.bg-black-opacity-60 {
    @include black-opacity(0.6);
}

.bg-black-opacity-70 {
    @include black-opacity(0.7);
}

.bg-black-opacity-80 {
    @include black-opacity(0.8);
}

.bg-black-opacity-90 {
    @include black-opacity(0.9);
}
