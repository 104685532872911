@use "../sg-core" as *;

.sg-eg-loader {
    .loading-icon {
        border-color: rgba($sg-color-primary-3, 0.25);
        border-top-color: $sg-color-primary-3;
    }

    .loading-text {
        color: $sg-color-primary-3;
    }
}
