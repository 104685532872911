.animation-shape-panel-image-panel {
    position: fixed;
    top: 1rem;
    left: 1rem;
    z-index: 9999;
    width: 200px;
    height: 300px;
    background: rgba(24, 28, 32, 1);
    font-size: 14px;
    text-align: left;
    padding: 0 1rem 1rem;
    display: flex;
    flex-direction: column;
    border-radius: 3px;
    overflow: hidden;

    &.is-collapsed {
        width: 2rem;
        height: 2rem;
        padding: 0;
    }

    .animation-shape-panel-button {
        background: rgba(41, 45, 57, 1);
        margin: 0 -1rem 1rem;
        padding: 0 1rem;
        border: 0 none;
        height: 40px;
        text-align: left;
        cursor: pointer;
    }

    .animation-shape-panel-button.is-collapsed {
        position: absolute;
        margin: 0;
        width: 2rem;
        height: 2rem;
        padding: 0;
        text-align: center;
    }

    .animation-shape-panel-button::after {
        content: '';
        display: inline-block;
        width: 0;
        height: 0;
        border-top: 7px solid rgba(83, 87, 96, 1);
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
    }

    .animation-shape-panel-button.is-collapsed::after {
        transform: rotate(-90deg);
    }

    .animation-shape-panel-info-text {
        margin: 0;
        margin-bottom: 1rem;
    }

    .animation-shape-panel-image {
        max-width: 100%;
        display: grid;
    }

    .animation-shape-panel-image.is-drag-active {
        transform: scale(1.1);
    }
}

