@use '../../1-core' as *;

@forward './widgets/search-result';

.algolia-search {
    .search-title {
        @include text-4xl;
        margin-bottom: $spacing-5;
    }

    .algolia-search-searchbar {
        margin-bottom: $spacing-5;
    }

    .algolia-search-results {
        .ais-Hits {
            margin-bottom: $spacing-5;
        }

        .ais-Pagination {
            display: flex;
            justify-content: center;
        }
    }
}
