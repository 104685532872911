@use "../../1-core" as *;
@use '../../../node_modules/swiper/swiper';
@use '../../../node_modules/swiper/modules/navigation/navigation';
@use '../../../node_modules/swiper/modules/pagination/pagination';

.swiper {
    min-height: 300px;

    --swiper-navigation-size: 48px;

    // custom styles go here
    --swiper-pagination-color: var(--swiper-theme-color);
    --swiper-pagination-bullet-size: 8px;
    --swiper-pagination-bullet-width: 8px;
    --swiper-pagination-bullet-height: 8px;
    --swiper-pagination-bullet-inactive-color: #{$black};
    --swiper-pagination-bullet-inactive-opacity: 0.2;
    --swiper-pagination-bullet-opacity: 1;
    --swiper-pagination-bullet-horizontal-gap: 4px;
    --swiper-pagination-bullet-vertical-gap: 6px;

    .swiper-button-prev::after,
    .swiper-button-next::after {
        content: '';
        font-family: inherit;
        position: absolute;
        inset: 0;
        background-color: $black;
        mask-position: center center;
        mask-repeat: no-repeat;
        mask-size: var(--swiper-navigation-size) var(--swiper-navigation-size);
    }

    .swiper-button-prev::after {
        mask-image: url('/assets/icons/chevron-left.svg');
    }

    .swiper-button-next::after {
        mask-image: url('/assets/icons/chevron-right.svg');
    }

    // https://github.com/nolimits4web/swiper/issues/3641
    .swiper-container-horizontal .swiper-slide {
        -webkit-backface-visibility: hidden;
        -webkit-transform: translate3d(0, 0, 0);
    }

    .swiper-container-horizontal .swiper-wrapper {
        -webkit-transform-style: preserve-3d;
    }
}
