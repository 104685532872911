$sg-spacing-1:         2px;
$sg-spacing-2:         4px;
$sg-spacing-3:         8px;
$sg-spacing-4:         16px;
$sg-spacing-5:         24px;
$sg-spacing-6:         32px;
$sg-spacing-7:         48px;
$sg-spacing-8:         64px;
$sg-spacing-9:         96px;
$sg-spacing-10:        128px;
