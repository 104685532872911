@use "../../1-core/" as *;

.particle-tooltip {
    visibility: hidden;
    position: absolute;
    z-index: 9999;
    width: calc(100% - 40px);
    max-width: 320px;
    padding: 17px 22px 23px;
    background: $white;
    color: $color-1-500;
    border-radius: 4px;
    text-align: left;
    outline: none;

    &.my-star {
        min-width: clamp(280px, calc(100vw - 20px), 346px);
    }

    &.is-visible {
        transition-property: top, left;
        transition-duration: 0.3s;
        visibility: visible;
    }

    &.is-no-transitions {
        transition: none;
    }

    .particle-tooltip-title {
        position: relative;
        z-index: 1;
        margin: 0 0 6px;
        font-size: 18px;
        line-height: 28px;
        color: $color-1-500;
    }

    .particle-tooltip-text {
        position: relative;
        z-index: 1;
        margin: 0 0 10px;
        font-size: 16px;
        line-height: 24px;
        color: $grey-1-700;

        &:empty {
            display: none;
        }
    }

    .particle-tooltip-link {
        cursor: pointer;
        background: none;
        border: 0 none;
        padding: 0;
        position: relative;
        z-index: 1;
        color: $color-1-500;
        text-decoration: underline;

        &:hover {
            color: $color-2-500;

            &::after {
                content: '';
                position: absolute;
                background: $color-2-500;
                mask-image: url('/assets/icons/arrow-right.svg');
                mask-position: center center;
                mask-repeat: no-repeat;
                width: $spacing-5;
                height: 100%;
                display: inline-block;
                margin-left: $spacing-1;
            }
        }
    }

    .particle-tooltip-donate {
        display: inline-flex;
    }

    .particle-tooltip-hide {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
        padding: 0;
        width: 40px;
        height: 40px;
        cursor: pointer;
        background: none;
        border: 0 none;
    }

    .particle-tooltip-hide-icon {
        width: 12px;
        height: 12px;
    }

    .particle-tooltip-arrow {
        transition-property: top, left;
        transition-duration: 0.3s;
        transform: translateX(-50%) translateY(-50%) rotate(45deg);
        width: 14px;
        height: 14px;
        background: $white;
        position: absolute;
        top: 0;
        left: 0;

        &.is-no-transitions {
            transition: none;
        }
    }

    .particle-tooltip-pill {
        margin: 0;
        padding: $spacing-1;
        border-radius: 4px;
        background: $grey-1-50;
        text-align: center;
    }
}
