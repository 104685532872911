@use '../../../1-core/' as *;
@use '../../../3-components/Buttons/buttons' as *;

.hits-empty-state {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: $spacing-8;

    .hits-empty-state-title {
        @include text-3xl;
        @include text-bold;
        margin-bottom: $spacing-3;
    }

    .hits-empty-state-description {
        margin-bottom: $spacing-6;
    }

    .ais-ClearRefinements .ais-ClearRefinements-button {
        @include button-skin('primary');
        @include button-size('md');
    }
}
