@use "../../1-core/colours" as *;
@use "../../1-core/grids-breakpoints" as *;
@use "../../1-core/spacing-scale" as *;
@use "../../1-core/typography/text-styles" as *;

$donation-toggle-button-min-width               : 88px;
$donation-toggle-button-container-border        : 1px solid $white;
$donation-toggle-button-padding                 : 6px $spacing-3;
$donation-display-toggle-button-border-radius   : calc(#{$spacing-4} - 2px);

.display-toggle-box {
    padding: $spacing-1;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .display-toggle-box-pause {
        padding-right: 14px;
        margin-right: 18px;
        min-height: 30px;
        border-right: 1px solid $white;
        display: none;
    }

    .toggle-label {
        display: block;
        margin-right: $spacing-2;
    }

    .toggle-button-container {
        display: flex;
        border: $donation-toggle-button-container-border;
        border-radius: $spacing-4;
        background-color: transparent;

        .display-toggle-button {
            min-width: $donation-toggle-button-min-width;
            padding: $donation-toggle-button-padding;
            background-color: transparent;
            border: none;
            color: $white;
            border-radius: $donation-display-toggle-button-border-radius;
            position: relative;
            transition: none;
            margin: 0;

            &.active {
                background-color: $white;
                color: $color-1-500;
            }
        }
    }
}

@media (max-width: $screen-sm-max) {
    .display-toggle-box {
        .toggle-label {
            @include text-sm;
        }

        .toggle-button-container .display-toggle-button {
            padding: $spacing-1 $spacing-2;
            min-width: 72px;
            min-height: 32px;
        }
    }
}

@media (min-width: $screen-lg) {
    .display-toggle-box {
        .display-toggle-box-pause.is-active {
            display: flex;

            &:empty {
                border: 0 none;
            }
        }
    }
}


@media (min-width: $screen-lg) {
    .display-toggle-box {
        padding: 0;

        .toggle-label {
            margin-right: $spacing-4;
        }

        .toggle-button-container {
            .display-toggle-button {
                min-width: $spacing-11;
            }
        }

        .stand-out & {
            p,
            .display-toggle-button {
                color: $color-1-500;
            }

            .toggle-button-container {
                border-color: $grey-1-100;
            }

            .display-toggle-button {
                &.active {
                    background-color: $color-1-500;
                    color: $white;
                }
            }
        }
    }
}
