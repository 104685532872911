/* stylelint-disable color-no-hex */
$sg-color-primary-2:    #ff5438;
$sg-color-primary-3:    #4716ed;
$sg-color-shade-1:      #54565a;
$sg-color-shade-2:      #767676;
$sg-color-shade-3:      #eee;
$sg-color-shade-4:      #f6f6f6;
$sg-color-accent-1:     #4716ed;
$sg-color-accent-hover-1: #2300ff;

$sg-white:                 #fff;
$sg-grey-1-100:         #f5f7f7;
$sg-grey-1-200:         #e1e6ea;
$sg-grey-1-300:         #c8d0d5;
$sg-grey-1-400:         #afb9c0;
$sg-grey-1-500:         #97a2aa;
$sg-grey-1-600:         #7c8c98;
$sg-grey-1-700:         #5b6b76;
$sg-grey-1-800:         #3b4954;
$sg-grey-1-900:         #222a30;
$sg-black:                 #000;

$info-box-blue-100:     #dfedf6;
$info-box-blue-500:     #004d85;

$info-box-green-100:    #eafbf9;
$info-box-green-500:    #0d7367;

$info-box-red-100:      #fcefee;
$info-box-red-500:      #c85647;

$info-box-yellow-100:   #fffbf5;
$info-box-yellow-500:   #da923e;
/* stylelint-enable color-no-hex */
