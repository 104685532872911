@mixin object-align($alignment, $fit: cover) {
    /* Because of the polyfill we use to make object-fit work with IE11, we have to set object-fit and object-postion at the same time for every instance */
    object-fit: $fit; // Generally we don't want to force uplaoded images to fit. That's what the alignment classes are for.
    object-position: $alignment;
}


@mixin align-top-left {
    @include object-align(top left);
}

.align-top-left {
    @include align-top-left;
}

@mixin align-top-center {
    @include object-align(top center);
}

.align-top-center {
    @include align-top-center;
}

@mixin align-top-right {
    @include object-align(top right);
}

.align-top-right {
    @include align-top-right;
}

@mixin align-center-left {
    @include object-align(center left);
}

.align-center-left {
    @include align-center-left;
}

@mixin align-center-center {
    @include object-align(center center);
}

.align-center-center {
    @include align-center-center;
}

@mixin align-center-right {
    @include object-align(center right);
}

.align-center-right {
    @include align-center-right;
}

@mixin align-bottom-left {
    @include object-align(bottom left);
}

.align-bottom-left {
    @include align-bottom-left;
}

@mixin align-bottom-center {
    @include object-align(bottom center);
}

.align-bottom-center {
    @include align-bottom-center;
}

@mixin align-bottom-right {
    @include object-align(bottom right);
}

.align-bottom-right {
    @include align-bottom-right;
}
