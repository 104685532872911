@use "colours" as *;

// Elevation Shadow System
// stylelint-disable
$light-shadows: (
    xs: (0 1px 2px rgba($grey-1-500, 0.2), 0 0 1px rgba($grey-1-900, 0.3)),
    sm: (0 3px 6px rgba($grey-1-500, 0.15), 0 0 1px rgba($grey-1-900, 0.4)),
    md: (0 6px 12px rgba($grey-1-500, 0.15), 0 0 1px rgba($grey-1-900, 0.4)),
    lg: (0 12px 24px rgba($grey-1-500, 0.15), 0 0 1px rgba($grey-1-900, 0.4)),
    xl: (0 24px 48px rgba($grey-1-500, 0.15), 0 0 1px rgba($grey-1-900, 0.4)),
    2xl: (0 36px 72px rgba($grey-1-500, 0.15), 0 0 1px rgba($grey-1-900, 0.4))
);

$color-1-shadows: (
    xs: (0 1px 2px rgba($color-1-500, 0.2), 0 0 1px rgba($color-1-500, 0.3)),
    sm: (0 3px 6px rgba($color-1-500, 0.15), 0 0 1px rgba($color-1-500, 0.4)),
    md: (0 6px 12px rgba($color-1-500, 0.15), 0 0 1px rgba($color-1-500, 0.4)),
    lg: (0 12px 24px rgba($color-1-500, 0.15), 0 0 1px rgba($color-1-500, 0.4)),
    xl: (0 24px 48px rgba($color-1-500, 0.15), 0 0 1px rgba($color-1-500, 0.4)),
    2xl: (0 36px 72px rgba($color-1-500, 0.15), 0 0 1px rgba($color-1-500, 0.4))
);

$color-2-shadows: (
    xs: (0px 1px 2px rgba($color-2-500, 0.2), 0px 0px 1px rgba($color-2-500, 0.3)),
    sm: (0px 3px 6px rgba($color-2-500, 0.15), 0px 0px 1px rgba($color-2-500, 0.4)),
    md: (0px 6px 12px rgba($color-2-500, 0.15), 0px 0px 1px rgba($color-2-500, 0.4)),
    lg: (0px 12px 24px rgba($color-2-500, 0.15), 0px 0px 1px rgba($color-2-500, 0.4)),
    xl: (0px 24px 48px rgba($color-2-500, 0.15), 0px 0px 1px rgba($color-2-500, 0.4)),
    2xl: (0px 36px 72px rgba($color-2-500, 0.15), 0px 0px 1px rgba($color-2-500, 0.4))
);

$dark-shadows: (
    xs: (0px 1px 2px rgba($grey-1-900, 0.9), 0px 0px 1px $grey-1-900),
    sm: (0px 3px 6px rgba($grey-1-900, 0.9), 0px 0px 1px $grey-1-900),
    md: (0px 6px 12px rgba($grey-1-900, 0.9), 0px 0px 1px $grey-1-900),
    lg: (0px 12px 24px rgba($grey-1-900, 0.9), 0px 0px 1px $grey-1-900),
    xl: (0px 24px 48px rgba($grey-1-900, 0.9), 0px 0px 1px $grey-1-900),
    2xl: (0px 36px 72px rgba($grey-1-900, 0.9), 0px 0px 1px $grey-1-900)
);

// stylelint-enable
@mixin light-shadow($key) {
    box-shadow: map-get($light-shadows, $key);
}

@mixin color-1-shadow($key) {
    box-shadow: map-get($color-1-shadows, $key);
}

@mixin color-2-shadow($key) {
    box-shadow: map-get($color-2-shadows, $key);
}

@mixin dark-shadow($key) {
    box-shadow: map-get($dark-shadows, $key);
}
