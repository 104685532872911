@use "../sg-core" as *;
@use "../../1-core/grids-breakpoints" as *;

.example-tiles {
    .c-base-tile,
    .c-bg-tile {
        width: 100%;
        margin-bottom: $sg-spacing-6;
    }
}

.example-horizontal-tiles {
    .c-base-tile {
        width: 100%;
        margin-bottom: $sg-spacing-6;
    }
}

@media (max-width: $screen-sm-max) {
    .example-tiles {
        .c-base-tile:last-of-type {
            margin-bottom: 0;
        }
    }
}

@media (min-width: $screen-md) {
    .example-tiles {
        .c-base-tile,
        .c-bg-tile {
            @include col(3);
        }

        .c-base-tile {
            display: flex;

            .tile-content {
                height: 100%;
            }
        }
    }
}

@media (min-width: $screen-lg) {
    .example-tiles {
        .c-base-tile,
        .c-bg-tile {
            @include col(3);
        }
    }
}
