@use "sg-components/SgHeader/sg-header";
@use "sg-components/SgFooter/sg-footer";
@use "sg-components/SgSitemap/sg-sitemap";
@use "sg-components/SgInfoBox/sg-info-box";
@use "sg-content";
@use "sg-page-content";
@use "sg-examples";

body.styleguide {
    background-color: transparent;
}
