@use "../../1-core" as *;

$label-color:           $grey-1-500;
$label-optional-color:  $grey-1-300;

@mixin base-label-styles {
    @include text-sm;
    color: $label-color;
    display: flex;
    align-items: center;
    margin-bottom: $spacing-2;

    .form-optional {
        @include text-xs;
        color: $label-optional-color;
        margin-left: $spacing-2;
    }
}

label {
    @include base-label-styles;
}
