// Set where the font files live
// =====================================================================
$font-path: '../assets/fonts';

// Add Font Face CSS for custom font files

// See https://caniuse.com/#search=font-face for file type support
// See https://css-tricks.com/snippets/css/using-font-face/ and https://css-tricks.com/understanding-web-fonts-getting/ for more information
// =======================================================================
@mixin fontFace($name, $filename, $weight, $style) {
    @font-face {
        font-display: swap;
        font-family: '#{$name}';
        src:
            url('#{$font-path}/#{$filename}.woff2') format('woff2'),
            url('#{$font-path}/#{$filename}.woff') format('woff');
        font-weight: $weight;
        font-style: $style;
    }
}

// Name, filename, weight (optional). NOTE: weight must NOT be a string.
@include fontFace('Poppins', 'poppins-regular', 400, normal);
@include fontFace('Poppins', 'poppins-600', 600, normal);
@include fontFace('Poppins', 'poppins-700', 700, normal);
@include fontFace('brownstd-regular', 'brownstd-regular', 400, normal);
@include fontFace('brownstd-bold', 'brownstd-bold', 700, normal);

// Reference web safe fonts
$font-sans: system-ui, -apple-system, blinkmacsystemfont, 'Segoe UI', roboto, 'Helvetica Neue', arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
$font-serif: georgia, cambria, 'Times New Roman', times, serif;
$font-mono: menlo, monaco, consolas, 'Liberation Mono', 'Courier New', monospace;

// Declare custom font stack w/ websafe fallback
// =======================================================================
$font-poppins: 'Poppins', $font-sans;

$font-brownbold: 'brownstd-bold', $font-sans;
$font-brownregular: 'brownstd-regular', $font-sans;
