@use "../../1-core/grids-breakpoints" as *;
@use "../../1-core/spacing-scale" as *;

.pagination {
    display: flex;
    // gap: $spacing-6;
    align-items: center;
    margin-top: $spacing-7;
    padding: 0 $spacing-3 $spacing-6;

    .pagination-info {
        display: none;
    }

    .pagination-buttons-container {
        flex: 1;
        display: flex;
        justify-content: center;
    }

    ul.pagination-buttons-container li {
        padding: 0;
        margin: 0;

        &::before {
            display: none;
        }
    }
}

@media (min-width: $screen-lg) {
    .pagination {
        .pagination-info {
            display: block;
        }

        .pagination-buttons-container {
            justify-content: center;
        }
    }
}
