@use "../../1-core/" as *;

@mixin image-caption {
    @include text-sm;
    margin: $spacing-3 0 0;
    color: $grey-1-500;
}

figcaption {
    @include image-caption;
}
