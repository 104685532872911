@use "../sg-core" as *;
@use "../../1-core/typography/text-styles" as *;
@use "../../1-core/typography/rich-text-styles-and-spacing" as *;
@use "../../1-core/typography/link-styles" as *;

.eg-text-styles {
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: $sg-spacing-6;
    row-gap: $sg-spacing-7;
    align-items: center;
}

.eg-rich-text-styles {
    @include rich-text-spacing;
}

.eg-link-styles {
    > *:not(:last-child) {
        margin-bottom: $sg-spacing-5;
    }

    a {
        @include text-link;
    }
}
