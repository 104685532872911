@use "../../1-core/colours" as *;
@use "../../1-core/spacing-scale" as *;
@use "sass:math";

$progress-bar-height        : 20px;
$progress-bar-border-radius : math.div($progress-bar-height, 2);

.progress-bar {
    background-color: $grey-1-300;
    height: $progress-bar-height;
    position: relative;
    width: 100%;
    border-radius: $progress-bar-border-radius;

    .progress-value {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        height: $progress-bar-height;
        border-radius: $progress-bar-border-radius;
        background-color: $support-success-500;
    }
}
