@use "../../1-core/" as *;

$loader-color:              $color-2-500 !default;
$loader-size:               $spacing-9 !default;
$loader-height:             $spacing-5 !default;
$loader-border-size:        $spacing-2 !default;
$loader-gap:                $spacing-3 !default;
$loader-animation-duration: 1s !default;

@mixin loader-rotate {
    @keyframes loader-rotate {
        0% {
            transform: rotate(0);
        }

        100% {
            transform: rotate(360deg);
        }
    }
}

@mixin loader-scale {
    @keyframes loader-scale {
        0% {
            transform: scale(0);
            opacity: 0;
        }

        50% {
            opacity: 1;
        }

        100% {
            transform: scale(1);
            opacity: 0;
        }
    }
}

@mixin loader02(
    $size: $loader-size,
    $color: $loader-color,
    $border-size: $loader-border-size,
    $duration: $loader-animation-duration,
    $align: null
) {
    width: $size;
    height: $size;
    border: $border-size solid rgba($color, 0.25);
    border-top-color: $color;
    border-radius: 50%;
    position: relative;
    animation: loader-rotate $duration linear infinite;
    @if ($align == center) {
        margin: 0 auto;
    }
    @if ($align == middle) {
        top: 50%;
        margin: -$size / 2 auto 0;
    }
    @include loader-rotate;
}


.loading-indicator {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: map-get($z-index, 'mega-menu');
    margin-top: $spacing-3;

    .loading-icon,
    .loading-text {
        z-index: map-get($z-index, 'mega-menu-item');
    }

    .loading-icon {
        @include loader02($color: $color-2-500);
    }

    .loading-text {
        margin-top: $spacing-4;
        @include text-lg;
        // color: $color-2-500;
    }

    .loading-image {
        width: $loader-size;
    }
}
