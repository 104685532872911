// Don't want to couple the React and non-React modal classes too tightly.
@use '../../1-core/' as *;
@use "../Buttons/buttons" as *;

body {
    .ReactModalPortal .modal-overlay {
        animation: fadeOut 0.3s cubic-bezier(0, 0, 0.2, 1);
    }
}

body.ReactModal__Body--open {
    overflow: hidden;

    .ReactModalPortal .modal-overlay {
        animation: fadeIn 0.3s cubic-bezier(0, 0, 0.2, 1);
    }
}

.ReactModalPortal {
    .modal-container,
    .modal-overlay {
        will-change: transform;
    }

    .modal-container {
        overflow-x: hidden;
    }

    .modal-overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba($dark-grey, 0.8);
        z-index: map-get($z-index, modal);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .modal-container {
        background: $white;
        // padding: $spacing-6;
        min-width: 400px;
        max-width: 600px;
        max-height: 95vh;
    }

    .modal-header {
        display: flex;
        justify-content: flex-end;
        position: absolute;
        align-items: center;
        background: $white;
        padding-right: $spacing-3;
        right: 0;
        border-bottom-left-radius: 5px;

        span {
            display: inline-block;
            color: $color-1-500;
            padding-bottom: $spacing-3;
            padding-top: $spacing-3;
            padding-left: $spacing-5;
        }
    }

    .modal-close {
        flex-shrink: 0;
        @include button-reset;
        cursor: pointer;
        z-index: map-get($z-index, 'modal-close');
        display: flex;
        justify-content: center;
        align-items: center;
        color: $color-1-500;

        span {
            margin-right: $spacing-2;
            border-bottom: 1px solid transparent;
        }

        .svg-icon {
            pointer-events: none;
            width: $spacing-4;
        }

        &:hover {
            span {
                border-bottom: 1px solid $color-1-500;
            }
        }

        &:focus-visible {
            outline: 2px solid $support-info-500;
            outline-offset: 2px;
        }
    }

    .modal-content {
        @include rich-text-spacing;
    }
}

@media (min-width: $screen-lg) {
    .ReactModalPortal {
        .modal-header {
            background: transparent;
            width: 100%;
            padding-right: $spacing-5;
            top: $spacing-4;
            border-bottom-left-radius: 0;

            span {
                padding: 0;
            }
        }
    }
}
