@use '../../1-core/typography/list-styles' as *;

:root {
    --particle-dim-on-hover: 0.3;
    --particle-dim-on-select: 0.1;
}

.particle {
    --clickable-area-size: 50%;
    --visible-size: 260%;
    //^ Values must use `%` unit.
    //Otherwise the corresponding JS may break.
    //See the .tsx file for the reference.

    @include list-item-reset;
    position: absolute;
    transform-style: preserve-3d;
    pointer-events: none;

    .particle-button {
        padding: 0;
        width: var(--clickable-area-size);
        height: var(--clickable-area-size);
        background: none;
        border: none;
        border-radius: 100%;
        pointer-events: all;
        transform-style: preserve-3d;
        -webkit-user-select: none;
        -webkit-tap-highlight-color: transparent;
        cursor: not-allowed;

        &.is-ignore-events {
            pointer-events: none;
        }

        &.is-filtered-out {
            pointer-events: none;
        }

        &:focus {
            outline: none;
            box-shadow: none;
        }

        &:not([disabled]) {
            cursor: pointer;

            &.is-selected {
                opacity: 1 !important; /* override JS animation */
            }

            &:focus-visible,
            &:hover {
                outline: none;
                box-shadow: none;
                opacity: 1 !important; /* override JS animation */
            }

            &::after {
                transition: opacity 0.2s;
                content: '';
                opacity: 0;
                transform: scale(0.85);
                position: absolute;
                left: calc((100% - var(--visible-size)) / 2);
                top: calc((100% - var(--visible-size)) / 2);
                width: calc(var(--visible-size));
                height: calc(var(--visible-size));
                background: url(/assets/images/particle-outline.png) 50% 50% no-repeat;
                background-size: contain;
                pointer-events: none;
            }

            &:focus-visible::after,
            &:hover::after,
            &.is-selected::after {
                opacity: 1;
            }
        }
    }

    .particle-image {
        transition-property: opacity, transform;
        transition-duration: 0.3s;
        position: absolute;
        left: calc((100% - var(--visible-size)) / 2);
        top: calc((100% - var(--visible-size)) / 2);
        width: var(--visible-size);
        height: var(--visible-size);
        background: url(/assets/images/particle-star.png) 50% 50% no-repeat;
        background-size: contain;
        pointer-events: none;

        &.story {
            background-image: url(/assets/images/particle-star-orange.png);
        }

        &.my-star {
            background-image: url('/assets/images/particle-star-my.png');
        }

        &.empty {
            background-image: url(/assets/images/particle-star-empty.png);
        }

        &.is-other-highlighted {
            opacity: var(--particle-dim-on-hover);
        }

        &.is-other-selected {
            opacity: var(--particle-dim-on-select);
        }

        &.is-filtered-out {
            opacity: 0.1;
            transform: scale(0.5);
        }
    }

    .particle-button:focus-visible .particle-image,
    .particle-button:hover .particle-image {
        opacity: 1 !important; /* override JS animation */
    }

    .particle-shimmer {
        --size-multiplier: 2;
        content: '';
        position: absolute;
        left: calc((100% - var(--visible-size) * var(--size-multiplier)) / 2);
        top: calc((100% - var(--visible-size) * var(--size-multiplier)) / 2);
        width: calc(var(--visible-size) * var(--size-multiplier));
        height: calc(var(--visible-size) * var(--size-multiplier));
        background: url(/assets/images/particle-star-blurred.png) 50% 50% no-repeat;
        background-size: contain;
        opacity: 0;
        pointer-events: none;
    }

    .particle-masked-image {
        --scale-adjustments: 10%;
        position: absolute;
        transition: 0.5s opacity;
        left: calc((100% - var(--visible-size) - var(--scale-adjustments)) / 2);
        top: calc((100% - var(--visible-size) - var(--scale-adjustments)) / 2);
        width: var(--visible-size) + var(--scale-adjustments);
        max-width: none;
        height: calc(var(--visible-size) + var(--scale-adjustments));
        -webkit-mask-image: url(/assets/images/particle-star.png);
        -webkit-mask-size: cover;
        pointer-events: none;
        opacity: 0;

        &.is-selected {
            opacity: 1;
        }
    }
}
