@use "../../1-core/colours" as *;
@use "../../1-core/spacing-scale" as *;

$donation-progress-border           : 1px solid $black;
$donation-progress-border-radius    : 5px;

.donation-progress-box {
    border: $donation-progress-border;
    border-radius: $donation-progress-border-radius;
    padding: $spacing-4 $spacing-3;

    .progress-subtitle {
        margin-top: $spacing-2;
    }
}
