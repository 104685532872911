@use "../../1-core/colours" as *;
@use "../../1-core/grids-breakpoints" as *;
@use "../../1-core/spacing-scale" as *;
@use "../../1-core/typography/text-styles" as *;
$donation-list-item-mystar-image-size           : 96px;
$donation-list-item-mystar-image-padding        : 6px;
$donation-list-item-mystar-image-filter         : drop-shadow(0 0 16px rgba(255, 255, 255, 0.4));

.donation-list-item-mystar {
    background-color: $color-1-700;
    display: flex;
    flex-direction: column;
    padding: $spacing-5 $spacing-4;
    margin-bottom: 0;
    border-radius: 5px;

    &::before {
        display: none;
    }

    .mystar-item-content {
        display: flex;
        gap: $spacing-4;
        align-items: flex-start;
    }

    .mystar-share.desktop-hide {
        padding: 0 $spacing-3;
    }

    .donation-list-image {
        width: $donation-list-item-mystar-image-size;
        height: $donation-list-item-mystar-image-size;
        object-fit: contain;
        padding: $donation-list-item-mystar-image-padding;
        filter: $donation-list-item-mystar-image-filter;
    }

    .donation-list-info {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        // padding-right: $spacing-4;
    }

    .donation-list-intro {
        flex: 1;
    }

    .intro-title {
        @include text-md;
        // @include text-xl;
        display: flex;
        align-items: center;
        margin-bottom: $spacing-2;
    }

    .hide-mobile {
        display: none;
    }

    .donation-amount {
        background-color: $color-1-500;
        min-width: 160px;
        text-align: center;
        padding: $spacing-1 $spacing-4;
        border-radius: $spacing-1;
        margin-top: $spacing-2;

        .donation-total {
            color: $white;
        }

        span {
            display: inline-block;
            color: $grey-1-100;
            margin-left: $spacing-1;

            &:first-child {
                margin-left: 0;
            }
        }
    }
}

@media (max-width: $screen-md-max) {
    .donation-list-item-mystar {
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);

        .mobile-hide {
            display: none;
        }
    }
}

@media (min-width: $screen-lg) {
    .donation-list-item-mystar {
        padding: $spacing-4 $spacing-3 $spacing-4 $spacing-1;

        .donation-list-info {
            flex-direction: row;
            padding-right: 0;
        }

        .mystar-share.desktop-hide {
            display: none;
        }

        .donation-amount {
            margin: 0;
            padding: $spacing-3 $spacing-4;

            span {
                display: block;
                margin: 0;
            }
        }

        .intro-title {
            @include text-lg;
        }
    }
}
