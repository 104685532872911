@use "../../1-core/grids-breakpoints" as *;

.column-container {
    &.cols-2,
    &.cols-3,
    &.cols-4,
    &.cols-5,
    &.cols-6 {
        &.no-gap {
            column-gap: 0;
            row-gap: 0;
        }

        &.no-column-gap {
            column-gap: 0;
        }

        &.no-row-gap {
            row-gap: 0;
        }
    }
}

@media (min-width: 0) and (max-width: $screen-sm-max) {
    .column-container {
        @include one-col-grid;
    }
}

@media (min-width: $screen-md) {
    .column-container {
        &.cols-2,
        &.cols-3,
        &.cols-4 {
            @include two-col-grid;
        }
    }
}

@media (min-width: $screen-md) and (max-width: $screen-md-max) {
    .column-container {
        &.cols-3 {
            // > div {
            //     &:nth-child(odd):last-child {
            //         grid-column-end: span 2;
            //     }
            // }
        }

        &.cols-5,
        &.cols-6 {
            @include three-col-grid;
        }
    }
}

@media (min-width: $screen-lg) {
    .column-container {
        &.cols-3 {
            @include three-col-grid;
        }

        &.cols-4 {
            @include four-col-grid;
        }

        &.cols-5 {
            @include five-col-grid;
        }

        &.cols-6 {
            @include six-col-grid;
        }
    }
}
