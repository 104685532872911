@use "../../1-core/colours" as *;
@use "../../1-core/grids-breakpoints" as *;
@use "../../1-core/spacing-scale" as *;
@use "../../1-core/typography/text-styles" as *;
@use "../../1-core/typography/rich-text-styles-and-spacing" as *;
@use "../../1-core/typography/font-weights" as *;


$page-404-flame-width                       : 80px;
$page-404-flame-height                      : 121px;
$page-404-border-line                       : 1px solid rgba($color: $black, $alpha: 0.4);
$page-404-page-content-max-width            : 440px;
$page-404-page-mystar-image-filter         : drop-shadow(0 0 16px rgba(255, 255, 255, 0.4));

.page-404 {
    width: 100vw;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: $spacing-4;
    position: relative;

    .page-404-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        max-width: $page-404-page-content-max-width;
        width: 100%;

        .page-404-flame {
            width: $page-404-flame-width;
            height: $page-404-flame-height;
            margin-bottom: $spacing-1;
            filter: $page-404-page-mystar-image-filter;
        }

        .page-404-title {
            @include text-2xl;
            @include text-bold;
            margin-top: $spacing-5 + $spacing-1;
            margin-bottom: 0;
        }

        .page-404-subtitle {
            @include body-base;
            margin-top: $spacing-3;
        }

        .page-404-main-button {
            margin-top: $spacing-7 + $spacing-1;
        }
    }
}

@media (min-width: $screen-lg) {
    .page-404 {
        .page-404-content {
            .page-404-flame {
                margin-bottom: $spacing-2;
            }

            .page-404-title {
                @include text-3xl;
                margin-bottom: 0;
            }

            .page-404-subtitle {
                @include body-base;
            }
        }
    }
}
