@use "font-stacks" as *;
@use "text-styles" as *;
@use "font-weights" as *;
@use "../colours" as *;

// Base Site Setup
// =======================================================================
html {
    // font-size: $base-document-size; //set in type-mixins;
}

body {
    font-family: $font-brownregular;
    @include text-base;
    position: relative;

    &:not(.styleguide) {
        background-color: $color-1-900;
        color: $white;

        &::after {
            content: '';
            height: 100%;
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            // background: rgb(69, 53, 128);
            background: radial-gradient(41.87% 54.04% at 52.03% 69.92%, $color-1-300 0%, $color-1-900 100%);
            background-attachment: fixed;
            z-index: -1;
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        @include text-bold;
    }
}

p {
    @include text-base;
}
