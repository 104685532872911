@use "../../1-core" as *;

// Accordions
//----------------------------------------
$accordion-icon-color:              $grey-1-900;
$accordion-border-color:            $grey-1-300;

$accordion-heading-padding:         $spacing-3 $spacing-3 12px;
$accordion-heading-color:           $grey-1-900;
$accordion-heading-bg:              $white;
$accordion-heading-hover-bg:        $grey-1-100;

$accordion-item-open-margin-bottom: $spacing-5;

$accordion-content-padding:         $spacing-3 $spacing-3 0;
$accordion-content-color:           $grey-1-900;
$accordion-content-transition:      all 150ms cubic-bezier(0.17, 0.04, 0.03, 0.94);

.accordion {
    .accordion-item {
        display: block;
        @include transition(margin-bottom, 300ms);
        margin-bottom: $spacing-3;

        .accordion-heading {
            button.accordion-title {
                @include text-lg;
                border: none;
                background: none;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: $accordion-heading-padding;
                color: $accordion-heading-color;
                position: relative;
                width: 100%;
                cursor: pointer;
                @include transition(background-color);

                &::after {
                    content: '';
                    position: absolute;
                    height: 1px;
                    left: $spacing-3;
                    right: $spacing-3;
                    bottom: $spacing-3;
                    background-color: $accordion-border-color;
                }

                &:hover,
                &:focus-visible {
                    @include transition(background-color, 200ms);
                    background-color: $accordion-heading-hover-bg;
                }
            }

            .svg-icon {
                flex-shrink: 0;
                color: $accordion-icon-color;
                transition: $accordion-content-transition;
            }
        }

        .accordion-content {
            width: 100%;
            color: $accordion-content-color;
            overflow: hidden;
            height: 0;
            @include transition(height, 300ms);

            .accordion-content-display {
                @include rich-text-spacing;
                padding: $accordion-content-padding;
            }
        }

        &.is-open {
            margin-bottom: $accordion-item-open-margin-bottom;

            .accordion-heading {
                .svg-icon {
                    transform: rotateZ(180deg);
                }
            }
        }
    }
}
