@use "../../1-core/colours" as *;
@use "../../1-core/grids-breakpoints" as *;
@use "../../1-core/spacing-scale" as *;
@use "sass:math";

$pagination-button-size     : $spacing-7;
$pagination-button-radius   : math.div($spacing-7, 2);

.pagination-button {
    width: $pagination-button-size;
    height: $pagination-button-size;
    text-decoration: none;
    color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: $pagination-button-radius;
    border: none;
    background-color: transparent;
    cursor: pointer;

    &:not(div):hover {
        // background-color: $grey-1-300;
        border: 2px solid $color-2-500;
    }

    &.active {
        background-color: $color-2-500;
        color: $color-1-500;
        cursor: default;
    }
}

div.pagination-button:not(.active) {
    &:hover {
        background-color: unset;
        cursor: default;
    }
}

@media (max-width: $screen-xs-max) {
    .pagination-button.hide-mobile {
        display: none;
    }
}
