@use "../../1-core/colours" as *;
@use "../../1-core/spacing-scale" as *;
@use "../../1-core/typography/text-styles" as *;
@use "../../1-core/grids-breakpoints" as *;

$donation-list-item-story-image-size           : $spacing-11;
$donation-list-item-story-image-filter         : drop-shadow(0 0 16px rgba(246, 218, 73, 0.4));

.donation-list-item-story {
    display: flex;
    gap: $spacing-4;
    padding: $spacing-4 $spacing-4 $spacing-4 $spacing-5;
    border-bottom: 1px solid $color-1-100;
    margin-bottom: 0;

    &::before {
        display: none;
    }

    .star-button {
        padding: 0;
        border: none;
        background-color: transparent;
        width: $donation-list-item-story-image-size;
        height: $donation-list-item-story-image-size;
        cursor: pointer;

        &:hover .donation-list-image {
            filter: $donation-list-item-story-image-filter;
        }
    }

    .donation-list-image {
        width: $donation-list-item-story-image-size;
        height: $donation-list-item-story-image-size;
        object-fit: contain;
    }

    .intro-title {
        @include text-md;
        margin-bottom: $spacing-2;
    }

    .story-open-button {
        padding: 0;
        border: none;
        background-color: transparent;
        color: $white;
        cursor: pointer;
        margin-top: $spacing-2;
        text-decoration: underline;

        &:hover {
            color: $color-2-500;
        }
    }
}

@media (min-width: $screen-lg) {
    .donation-list-item-story {
        padding: $spacing-4 0 $spacing-4 $spacing-1;

        &:first-child {
            padding-top: 0;
        }

        .intro-title {
            @include text-lg;
        }
    }
}
