@use "../../1-core/" as *;

// Buttons
//----------------------------------------
$btn-skin-primary-bg:                     $color-2-500;
$btn-skin-primary-text-color:             $color-1-500;
$btn-skin-primary-border-color:           $color-2-500;

$btn-skin-primary-hover-bg:               $color-1-900;
$btn-skin-primary-hover-text-color:       $white;
$btn-skin-primary-hover-border-color:     $btn-skin-primary-hover-bg;

$btn-skin-primary-focus-bg:               $btn-skin-primary-hover-bg;
$btn-skin-primary-focus-text-color:       $btn-skin-primary-hover-text-color;
$btn-skin-primary-focus-border-color:     $btn-skin-primary-focus-bg;
$btn-skin-primary-focus-outline-color:    $btn-skin-primary-focus-bg;
$btn-skin-primary-focus-outline-width:    2px;
$btn-skin-primary-focus-outline-offset:   2px;

$btn-skin-primary-active-bg:              $color-2-500;
$btn-skin-primary-active-text-color:      $color-1-500;
$btn-skin-primary-active-border-color:    $btn-skin-primary-border-color;

$btn-skin-primary-disabled-bg:            $grey-1-50;
$btn-skin-primary-disabled-text-color:    $grey-1-100;
$btn-skin-primary-disabled-border-color:  $btn-skin-primary-disabled-bg;

$btn-skin-secondary-bg:                     $white;
$btn-skin-secondary-text-color:             $color-1-500;
$btn-skin-secondary-border-color:           $white;

$btn-skin-secondary-hover-bg:               $color-1-500;
$btn-skin-secondary-hover-text-color:       $white;
$btn-skin-secondary-hover-border-color:     $btn-skin-secondary-hover-bg;

$btn-skin-secondary-focus-bg:               $btn-skin-secondary-hover-bg;
$btn-skin-secondary-focus-text-color:       $btn-skin-secondary-hover-text-color;
$btn-skin-secondary-focus-border-color:     $btn-skin-secondary-focus-bg;
$btn-skin-secondary-focus-outline-color:    $btn-skin-secondary-focus-bg;
$btn-skin-secondary-focus-outline-width:    2px;
$btn-skin-secondary-focus-outline-offset:   2px;

$btn-skin-secondary-active-bg:              $white;
$btn-skin-secondary-active-text-color:      $color-1-500;
$btn-skin-secondary-active-border-color:    $btn-skin-secondary-border-color;

$btn-skin-secondary-disabled-bg:            $grey-1-50;
$btn-skin-secondary-disabled-text-color:    $grey-1-100;
$btn-skin-secondary-disabled-border-color:  $btn-skin-secondary-disabled-bg;

$btn-skin-ghost-bg:                     transparent;
$btn-skin-ghost-text-color:             $color-1-500;
$btn-skin-ghost-border-color:           $white;

$btn-skin-ghost-hover-bg:               $btn-skin-ghost-bg;
$btn-skin-ghost-hover-text-color:       $btn-skin-ghost-text-color;
$btn-skin-ghost-hover-border-color:     $btn-skin-ghost-hover-text-color;

$btn-skin-ghost-focus-bg:               $grey-1-50;
$btn-skin-ghost-focus-text-color:       $btn-skin-ghost-text-color;
$btn-skin-ghost-focus-border-color:     $btn-skin-ghost-hover-text-color;
$btn-skin-ghost-focus-outline-color:    $btn-skin-ghost-hover-text-color;
$btn-skin-ghost-focus-outline-width:    1px;
$btn-skin-ghost-focus-outline-offset:   2px;

$btn-skin-ghost-active-bg:              transparent;
$btn-skin-ghost-active-text-color:      $btn-skin-ghost-hover-text-color;
$btn-skin-ghost-active-border-color:    $white;

$btn-skin-ghost-disabled-bg:            $grey-1-50;
$btn-skin-ghost-disabled-text-color:    $grey-1-100;
$btn-skin-ghost-disabled-border-color:  $btn-skin-ghost-disabled-bg;

@mixin button-reset {
    padding: 0;
    text-decoration: none;
    background: none; // Button tag reset
    border: none; // Button tag reset
    cursor: pointer; // For Button tag
}

@mixin button-skin($skin: 'primary') {
    --btn-border-size: #{$spacing-6};
    @include button-reset;
    border-radius: var(--btn-border-size);
    background-color: var(--btn-bg);
    color: var(--btn-color);
    border: 1px solid var(--btn-border-color);
    @include transition(background-color border-color color outline);
    outline: var(--btn-outline);
    outline-offset: var(--btn-outline-offset);

    &.btn-disabled,
    &:disabled {
        cursor: default;

        &:hover {
            cursor: default;
        }
    }

    &:hover {
        @include transition(background-color border-color color, 200ms);
    }

    &:focus-visible {
        @include transition(background-color color, 200ms);
    }

    &:active {
        @include transition(background-color color, 200ms);
    }

    @if $skin == 'primary' {
        --btn-bg: #{$btn-skin-primary-bg};
        --btn-color: #{$btn-skin-primary-text-color};
        --btn-border-color: #{$btn-skin-primary-border-color};
        --btn-outline: none;
        --btn-outline-offset: 0;

        &:hover {
            --btn-bg: #{$btn-skin-primary-hover-bg};
            --btn-color: #{$btn-skin-primary-hover-text-color};
            --btn-border-color: #{$btn-skin-primary-hover-border-color};
        }

        &:focus-visible {
            --btn-bg: #{$btn-skin-primary-focus-bg};
            --btn-color: #{$btn-skin-primary-focus-text-color};
            --btn-border-color: #{$btn-skin-primary-focus-border-color};
            --btn-outline: #{$btn-skin-primary-focus-outline-width} solid #{$btn-skin-primary-focus-outline-color};
            --btn-outline-offset: #{$btn-skin-primary-focus-outline-offset};
        }

        &:active {
            --btn-bg: #{$btn-skin-primary-active-bg};
            --btn-color: #{$btn-skin-primary-active-text-color};
            --btn-border-color: #{$btn-skin-primary-active-border-color};
        }

        &.btn-disabled,
        &:disabled {
            --btn-bg: #{$btn-skin-primary-disabled-bg};
            --btn-color: #{$btn-skin-primary-disabled-text-color};
            --btn-border-color: #{$btn-skin-primary-disabled-border-color};
            --btn-outline: none;
        }
    }


    @if $skin == 'secondary' {
        --btn-bg: #{$btn-skin-secondary-bg};
        --btn-color: #{$btn-skin-secondary-text-color};
        --btn-border-color: #{$btn-skin-secondary-border-color};
        --btn-outline: none;
        --btn-outline-offset: 0;

        &:hover {
            --btn-bg: #{$btn-skin-secondary-hover-bg};
            --btn-color: #{$btn-skin-secondary-hover-text-color};
            --btn-border-color: #{$btn-skin-secondary-hover-border-color};
        }

        &:focus-visible {
            --btn-bg: #{$btn-skin-secondary-focus-bg};
            --btn-color: #{$btn-skin-secondary-focus-text-color};
            --btn-border-color: #{$btn-skin-secondary-focus-border-color};
            --btn-outline: #{$btn-skin-secondary-focus-outline-width} solid #{$btn-skin-secondary-focus-outline-color};
            --btn-outline-offset: #{$btn-skin-secondary-focus-outline-offset};
        }

        &:active {
            --btn-bg: #{$btn-skin-secondary-active-bg};
            --btn-color: #{$btn-skin-secondary-active-text-color};
            --btn-border-color: #{$btn-skin-secondary-active-border-color};
        }

        &.btn-disabled,
        &:disabled {
            --btn-bg: #{$btn-skin-secondary-disabled-bg};
            --btn-color: #{$btn-skin-secondary-disabled-text-color};
            --btn-border-color: #{$btn-skin-secondary-disabled-border-color};
            --btn-outline: none;
        }
    }

    @if $skin == 'ghost' {
        --btn-bg: #{$btn-skin-ghost-bg};
        --btn-color: #{$btn-skin-ghost-text-color};
        --btn-border-color: #{$btn-skin-ghost-border-color};
        --btn-outline: none;
        --btn-outline-offset: 0;

        &:hover {
            --btn-color: #{$btn-skin-ghost-hover-text-color};
            --btn-border-color: #{$btn-skin-ghost-hover-border-color};
        }

        &:focus-visible {
            --btn-bg: #{$btn-skin-ghost-focus-bg};
            --btn-color: #{$btn-skin-ghost-focus-text-color};
            --btn-border-color: #{$btn-skin-ghost-focus-border-color};
            --btn-outline: #{$btn-skin-ghost-focus-outline-width} solid #{$btn-skin-ghost-focus-outline-color};
            --btn-outline-offset: #{$btn-skin-ghost-focus-outline-offset};
        }

        &:active {
            --btn-bg: #{$btn-skin-ghost-active-bg};
            --btn-color: #{$btn-skin-ghost-active-text-color};
            --btn-border-color: #{$btn-skin-ghost-active-border-color};
        }

        &.btn-disabled,
        &:disabled {
            --btn-bg: #{$btn-skin-ghost-disabled-bg};
            --btn-color: #{$btn-skin-ghost-disabled-text-color};
            --btn-border-color: #{$btn-skin-ghost-disabled-border-color};
            --btn-outline: none;
        }
    }
}

.btn-skin-1 {
    @include button-skin('primary');
}

.btn-skin-2 {
    @include button-skin('secondary');
}

.btn-skin-3 {
    @include button-skin('ghost');
}

@mixin button-label($size: 'md') {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    // @include text-semibold;
    @include text-bold;
    gap: var(--label-gap);

    .svg-icon {
        width: var(--label-icon-width);
        height: var(--label-icon-width);
    }

    @if $size == 'lg' {
        @include text-md;

        --label-gap: #{$spacing-3};
        --label-icon-width: #{$icon-lg};
    }

    @if $size == 'md' {
        @include text-base;

        --label-gap: #{$spacing-3};
        --label-icon-width: #{$icon-md};
    }

    @if $size == 'sm' {
        @include text-sm;

        --label-gap: #{$spacing-2};
        --label-icon-width: #{$icon-sm};
    }
}

@mixin button-size($size: 'md', $onlyIcon: false) {
    padding: var(--btn-size-padding); // Button tag reset.

    @if $size == 'lg' {
        @include button-label('lg');

        @if $onlyIcon {
            --btn-size-padding: #{$spacing-4};
        }
        @else {
            --btn-size-padding: #{$spacing-4 $spacing-8};
        }
    }

    @if $size == 'md' {
        @include button-label('md');

        @if $onlyIcon {
            --btn-size-padding: #{$spacing-3};
        }
        @else {
            --btn-size-padding: #{$spacing-3 $spacing-5};
        }
    }

    @if $size == 'sm' {
        @include button-label('sm');

        @if $onlyIcon {
            --btn-size-padding: #{$spacing-2};
        }
        @else {
            --btn-size-padding: #{$spacing-2 $spacing-4};
        }
    }
}

.btn-size-lg {
    &:not(.btn-only-icon) {
        @include button-size('lg');
    }

    &.btn-only-icon {
        @include button-size('lg', true);
    }
}

.btn-size-md {
    &:not(.btn-only-icon) {
        @include button-size('md');
    }

    &.btn-only-icon {
        @include button-size('md', true);
    }
}

.btn-size-sm {
    &:not(.btn-only-icon) {
        @include button-size('sm');
    }

    &.btn-only-icon {
        @include button-size('sm', true);
    }
}
