@use '../../../1-core' as *;

// NOTE: This CSS is for the example custom hit only.
// Ideally the hit components should be styled by their own componenet CSS.
// I.e. if you CardProduct, use card-product.scss.
.eg-custom-hit-card {
    border: 1px solid $grey-1-100;
    padding: $spacing-3;
    height: 100%;
    display: flex;
    flex-direction: column;
    // align-items: flex-start;
    justify-content: flex-start;

    .hit-thumbnail {
        // image overrides
        margin-bottom: $spacing-5;

        picture {
            background-color: transparent;
        }
    }

    .hit-category {
        @include text-sm;
        text-transform: uppercase;
        color: $grey-1-300;
        margin-bottom: $spacing-2;
    }

    .hit-title {
        margin-bottom: $spacing-2;
    }

    .hit-description {
        @include text-sm;
        margin-bottom: $spacing-4;
    }

    .price-rating-wrapper {
        display: flex;
        justify-content: space-between;
        margin-top: auto; //align to bottom of div
    }

    .hit-price {
        // styles go here
    }

    .hit-rating {
        display: flex;
        align-items: center;

        p {
            margin-left: $spacing-1;
            line-height: 1;
        }
    }
}
