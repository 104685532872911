@use "../sg-core" as *;
@use "../../1-core/grids-breakpoints" as *;

.sg-eg-form-elements .container {
    @include two-col-grid;
    gap: $sg-spacing-8;

    .col {
        display: flex;
        flex-direction: column;
        gap: $sg-spacing-5;
    }
}

.sg-eg-form-validation .container form {
    @include one-col-grid;
    gap: $sg-spacing-5;
}
