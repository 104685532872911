@use "../../1-core/colours" as *;
@use "../../1-core/grids-breakpoints" as *;
@use "../../1-core/spacing-scale" as *;
@use "../../1-core/typography/text-styles" as *;

$thankyou-star-size-mobile                  : $spacing-9;
$thankyou-star-size-desktop                 : 106px;
$thankyou-border-line                       : 1px solid rgba($color: $black, $alpha: 0.4);
$thankyou-page-content-max-width-desktop    : 790px;
$thankyou-page-table                        : 550px;
$thankyou-page-content-table                : 380px;
$thankyou-page-mystar-image-filter         : drop-shadow(0 0 16px rgba(255, 255, 255, 0.4));

.thankyou-page {
    width: 100vw;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: $spacing-4;
    position: relative;

    .thankyou-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        .thankyou-star {
            width: $thankyou-star-size-mobile;
            height: $thankyou-star-size-mobile;
            margin-bottom: $spacing-1;
            filter: $thankyou-page-mystar-image-filter;
        }

        .thankyou-flame {
            width: $thankyou-star-size-mobile;
        }

        .thankyou-title {
            @include text-xl;
            margin-top: $spacing-2;
            margin-bottom: 0;
        }

        .thankyou-subtitle {
            @include text-lg;
            margin-top: $spacing-3;
        }

        .donation-share-box {
            border: $thankyou-border-line;
            border-radius: $spacing-1;
            padding: $spacing-3;
            padding-bottom: 0;
            margin-top: $spacing-6;
            max-width: $thankyou-page-table;
            width: 100%;

            .share-intro {
                margin: 0 auto $spacing-2;
                max-width: $thankyou-page-content-table;
            }
        }

        .line-separator {
            display: none;
        }

        .donation-share-button-container {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            .donation-share-button {
                color: white;
                justify-content: center;
                width: 100%;
                border-top: $thankyou-border-line;
                border-bottom: 0 none;
                border-left: 0 none;
                border-right: 0 none;
                border-radius: 0;
            }
        }

        .thankyou-main-button {
            margin-top: $spacing-6;
        }
    }

    h1,
    h2 {
        margin-top: $spacing-6;
    }

    .waiting-message-container {
        position: relative;
    }

    .thankyou-waiting {
        width: 100%;
        max-width: 600px;
        margin-top: -$spacing-12;

        .waiting-message {
            width: 100%;
            position: absolute;
            text-align: center;
            @include text-xl;
        }

        .fade-in {
            transition: opacity 1s ease;
        }

        .fade-out {
            opacity: 0;
            transition: opacity 1s ease;
        }
    }
}

@media (min-width: $screen-lg) {
    .thankyou-page {
        .thankyou-content {
            max-width: $thankyou-page-content-max-width-desktop;
            width: 100%;

            .thankyou-star {
                width: $thankyou-star-size-desktop;
                height: $thankyou-star-size-desktop;
                margin-bottom: $spacing-2;
            }

            .thankyou-title {
                @include text-4xl;
                margin-bottom: 0;
            }

            .thankyou-subtitle {
                @include text-xl;
                margin-top: $spacing-4;
            }

            .donation-share-box {
                padding: $spacing-4;
                margin-top: $spacing-8;
            }

            .line-separator {
                display: block;
                border-top: $thankyou-border-line;
                margin: $spacing-4 0;
            }

            .donation-share-button-container {
                display: grid;
                grid-template-columns: 1fr 1fr;

                .donation-share-button {
                    width: unset;
                    padding-left: $spacing-3;
                    padding-right: $spacing-3;
                    justify-content: center;
                    border: 1px solid transparent;

                    span {
                        padding-bottom: 2px;
                        border-bottom: 1px solid transparent;
                    }

                    &:hover span {
                        border-bottom: 1px solid $white;
                    }
                }
            }
        }

        .thankyou-waiting {
            width: 100%;
            max-width: $thankyou-page-content-max-width-desktop;

            .waiting-message {
                @include text-3xl;
            }
        }
    }
}
