@use "../../1-core/spacing-scale" as *;
@use "../../1-core/colours" as *;
@use "../../1-core/typography/rich-text-styles-and-spacing" as *;


.sg-eg-spacing {
    .spacing-unit {
        display: flex;
        margin-bottom: $spacing-3;

        p {
            margin-bottom: 0;
            width: $spacing-13;
        }

        .spacing-value {
            width: $spacing-13;
        }

        .spacing-var {
            display: block;
            background-color: $support-info-50;
        }
    }

    .spacing-var-1 {
        width: $spacing-1;
    }

    .spacing-var-2 {
        width: $spacing-2;
    }

    .spacing-var-3 {
        width: $spacing-3;
    }

    .spacing-var-4 {
        width: $spacing-4;
    }

    .spacing-var-5 {
        width: $spacing-5;
    }

    .spacing-var-6 {
        width: $spacing-6;
    }

    .spacing-var-7 {
        width: $spacing-7;
    }

    .spacing-var-8 {
        width: $spacing-8;
    }

    .spacing-var-9 {
        width: $spacing-9;
    }

    .spacing-var-10 {
        width: $spacing-10;
    }

    .spacing-var-11 {
        width: $spacing-11;
    }

    .spacing-var-12 {
        width: $spacing-12;
    }

    .spacing-var-13 {
        width: $spacing-13;
    }
}

.sg-eg-vertical-spacing {
    @include rich-text-spacing;
}
