@use "../../1-core/colours" as *;
@use "../../1-core/grids-breakpoints" as *;
@use "../../1-core/spacing-scale" as *;

$credit-text-link-gap           : 6px;
$credit-image-margin-bottom     : 4px;


.luminary-credit {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;

    p {
        color: $grey-1-100;
        margin-right: $credit-text-link-gap;
    }

    a {
        margin-bottom: $credit-image-margin-bottom;
    }
}

.luminary-credit.hidden {
    display: none;
}

@media (max-width: $screen-md-max) {
    .luminary-credit.mobile-hide {
        display: none;
    }
}

@media (min-width: $screen-lg) {
    .luminary-credit.desktop-hide {
        display: none;
    }
}
